import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import keycloak from "@/security/keycloak";
import Keycloak, { KeycloakProfile } from "keycloak-js";
import { formatNameInitials } from "@/utils/formatNameUtils";
import { hasRole } from "@/utils/groupUtils";
import { Role } from "@domain/enum/role";
import config from "@/config";
import { setToken } from "@/apiRequest";
import { toastError } from "@/utils/toastUtils";
import userApi from "@/api/userApi";
import * as Sentry from "@sentry/browser";
import { useUserProfileStore } from "./userProfileModule.pinia";
import SockitSingleton from "@/api/sockit/sockit";

const useKeycloakStore = defineStore(ModuleType.Keycloak, {
  state: () => ({
    auth: undefined as Keycloak | undefined,
    profile: undefined as KeycloakProfile | undefined,
    tmpIdProfileImage: "tmpUrl",
  }),
  getters: {
    getAuth(state): Keycloak | undefined {
      return state.auth?.isTokenExpired !== undefined ? state.auth : undefined;
    },

    isAuthenticated(state): boolean {
      return state.auth?.authenticated ?? false;
    },

    getProfile(state): KeycloakProfile | undefined {
      return state.profile;
    },

    profileAvailable(state): boolean {
      return state.profile?.attributes !== undefined;
    },

    getUserGroupNameList(state): string[] {
      return state.auth?.tokenParsed?.groups;
    },

    getRoles(state): string[] {
      return state.auth?.tokenParsed?.realm_access
        ? state.auth.tokenParsed.realm_access.roles
        : [];
    },

    firstName(state): string {
      return state.profile?.firstName ?? "Empty";
    },

    getInitialsOfName(state): string {
      if (state.profile?.firstName && state.profile?.lastName) {
        return formatNameInitials(
          state.profile.firstName[0],
          state.profile.lastName[0]
        );
      }
      return "?";
    },

    isSuperUser(): boolean {
      return hasRole(this.getRoles, Role.SUPER_USER);
    },

    isGroupAdmin(): boolean {
      return hasRole(this.getRoles, Role.GROUP_ADMIN);
    },

    isContributeur(): boolean {
      return hasRole(this.getRoles, Role.CONTRIBUTEUR);
    },

    idProfileImage(state): string {
      if (state.tmpIdProfileImage !== "tmpUrl") {
        return state.tmpIdProfileImage;
      }
      return state.profile?.attributes?.idProfileImage
        ? (state.profile?.attributes["idProfileImage"] as string[])[0]
        : "";
    },

    isPromoteur(): boolean {
      return hasRole(this.getRoles, Role.PROMOTEUR);
    },

    isBanque(): boolean {
      return hasRole(this.getRoles, Role.BANQUE);
    },

    isTiers(): boolean {
      return hasRole(this.getRoles, Role.TIERS);
    },

    isHommeDeArt(): boolean {
      return hasRole(this.getRoles, Role.HOMME_DE_ART);
    },

    isPromoteurOrBanqueUser(): boolean {
      return (
        hasRole(this.getRoles, Role.BANQUE) ||
        hasRole(this.getRoles, Role.PROMOTEUR)
      );
    },

    isSignataire(): boolean {
      return hasRole(this.getRoles, Role.SIGNATAIRE);
    },

    getPartnerRole(): Role | null {
      if (hasRole(this.getRoles, Role.PROMOTEUR)) {
        return Role.BANQUE;
      } else if (hasRole(this.getRoles, Role.BANQUE)) {
        return Role.PROMOTEUR;
      }
      return null;
    },

    hasReadLatestConditionGeneraleUtilisation(): boolean {
      if (
        this.profileAvailable &&
        this.getProfile?.attributes?.readConditionGeneraleUtilisationVersion
      ) {
        const latestCgu = (
          this.getProfile?.attributes[
            "readConditionGeneraleUtilisationVersion"
          ] as string[]
        )[0];

        return (
          !!latestCgu &&
          latestCgu === config.LATEST_CONDITION_GENERALE_UTILISATION_VERSION
        );
      }
      return false;
    },

    hasAlreadyLoggedInProvidedInformation(): boolean {
      return !!this.getProfile?.firstName && !!this.getProfile?.lastName;
    },
  },
  actions: {
    setIdProfileImage(IdProfileImage: string): void {
      this.tmpIdProfileImage = IdProfileImage;
    },

    setAuth(authData: Keycloak): void {
      this.auth = authData;
      if (this.auth.token) {
        setToken(this.auth.token);
      }
    },

    setProfile(profile: KeycloakProfile): void {
      this.profile = {
        ...profile,
        id: this.auth?.subject ? this.auth.subject : undefined,
      };
    },

    async authenticate(next: Function): Promise<void> {
      keycloak.onTokenExpired = (): void => {
        this.refreshToken();
      };

      return keycloak
        .init({
          onLoad: "login-required",
          checkLoginIframe: true,
        })
        .then(() =>
          keycloak
            .updateToken(10)
            .then(async () => {
              this.setAuth(keycloak);
              await keycloak
                .loadUserProfile()
                .then(async (currentProfile) => {
                  this.setProfile(currentProfile);
                  Sentry.setUser({
                    id: currentProfile.id,
                  });
                  await useUserProfileStore().fetchUserProfile(
                    currentProfile.id ?? ""
                  );
                  SockitSingleton.getInstance(keycloak.token ?? "");
                })
                .catch(() => {
                  console.log("Keycloak failed to load user profile");
                });
              next();
            })
            .catch(() => {
              console.log("Keycloak failed to refresh token");
            })
        )
        .catch((error) => {
          console.log("Authentication denied", error);
        });
    },

    async refreshToken(): Promise<void> {
      keycloak
        .updateToken(10)
        .then(async () => {
          this.setAuth(keycloak);
          await keycloak
            .loadUserProfile()
            .then(async (currentProfile) => {
              this.setProfile(currentProfile);
            })
            .catch(() => {
              console.log("Keycloak failed to load user profile");
            });
        })
        .catch((err) => {
          console.error("failed refresh token", err);
        });
    },

    async updateUserHasReadConditionGeneraleUtilisation(): Promise<void> {
      const idUser = this.getProfile?.id ?? "";
      return userApi
        .updateAttributes(idUser, {
          readConditionGeneraleUtilisationVersion:
            config.LATEST_CONDITION_GENERALE_UTILISATION_VERSION,
        })
        .then(() => {
          this.refreshToken();
        })
        .catch(() => {
          toastError(
            "Une erreur est survenue à la mise à jour de la prise de connaissance des conditions générales d'utilisation"
          );
        });
    },

    async disconnect(): Promise<void> {
      await keycloak.logout();
    },
  },
});

export default useKeycloakStore;
