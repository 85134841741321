import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import {
  OperationSuiviCommercialisation,
  OperationSuiviCommercialisationTotal,
} from "@domain/dto/operationSuiviCommercialisation";
import operationSuiviCommercialisationApi from "@/api/operationSuiviCommercialisationApi";
import commercialisationApi from "@/api/commercialisationApi";
import { TrancheDemandeCreationRequest } from "@domain/dto/trancheDemandeCreationRequest";
import { useOperationStore } from "./operationModule.pinia";
import { OperationSuiviComViewType } from "@/domain/enum/operationSuiviComViewType";
import { OperationSuiviCommercialisationResponse } from "@domain/dto/operationSuiviCommercialisationResponse";
import { formatDate } from "@/utils/dateUtils";

type OperationSuiviCommercialisationState = {
  operationSuiviCommercialisationResponse?: OperationSuiviCommercialisationResponse;
  operationSuiviCommercialisation: OperationSuiviCommercialisation | undefined;
  preferenceAffichage: keyof OperationSuiviCommercialisationTotal;
  operationSuiviComViewType: OperationSuiviComViewType;
};

export const useOperationSuiviCommercialisationStore = defineStore(
  ModuleType.OperationSuiviCommercialisation,
  {
    state: (): OperationSuiviCommercialisationState => ({
      operationSuiviCommercialisation: undefined,
      preferenceAffichage: "chiffreAffaire",
      operationSuiviComViewType: OperationSuiviComViewType.GROUP_BY_GFA,
    }),
    getters: {
      getOperationSuiviCommercialisation(
        state
      ): OperationSuiviCommercialisation | undefined {
        switch (state.operationSuiviComViewType) {
          case OperationSuiviComViewType.GROUP_BY_GFA:
            return state.operationSuiviCommercialisationResponse?.groupByGfa;
          case OperationSuiviComViewType.GROUP_BY_TRANCHE:
            return state.operationSuiviCommercialisationResponse
              ?.groupByTranche;
        }
      },
      getPenultimateOperationSuiviCommercialisation(
        state
      ): OperationSuiviCommercialisation | undefined {
        switch (state.operationSuiviComViewType) {
          case OperationSuiviComViewType.GROUP_BY_GFA:
            return state.operationSuiviCommercialisationResponse?.penultimate
              ?.penultimateGroupByGfa;
          case OperationSuiviComViewType.GROUP_BY_TRANCHE:
            return state.operationSuiviCommercialisationResponse?.penultimate
              ?.penultimateGroupByTranche;
        }
      },
      getPreferenceAffichage(
        state
      ): keyof OperationSuiviCommercialisationTotal {
        return state.preferenceAffichage;
      },
      getOperationSuiviComViewType(state): OperationSuiviComViewType {
        return state.operationSuiviComViewType;
      },
      getOperationSuiviCommercialisationResponse(
        state
      ): OperationSuiviCommercialisationResponse | undefined {
        return state.operationSuiviCommercialisationResponse;
      },
      getPenultimateImportDate(state): string {
        return formatDate(
          state.operationSuiviCommercialisationResponse?.penultimate
            ?.penultimateGroupByTranche.lastImport.date
        );
      },
    },
    actions: {
      ResetOperationSuiviCommercialisation(): void {
        this.operationSuiviCommercialisation = undefined;
      },
      SetPreferenceAffichage(
        preferenceAffichage: keyof OperationSuiviCommercialisationTotal
      ): void {
        this.preferenceAffichage = preferenceAffichage;
      },
      SetOperationSuiviComViewType(
        operationSuiviComViewType: OperationSuiviComViewType
      ): void {
        this.operationSuiviComViewType = operationSuiviComViewType;
      },
      async fetchOperationSuiviCommercialisation(
        idOperation: string
      ): Promise<OperationSuiviCommercialisation | undefined> {
        this.ResetOperationSuiviCommercialisation();

        const operationSuiviCommercialisationResponse =
          await operationSuiviCommercialisationApi.fetchForOperation(
            idOperation
          );

        if (operationSuiviCommercialisationResponse.status === 409) {
          return undefined;
        }

        this.operationSuiviCommercialisationResponse =
          operationSuiviCommercialisationResponse.data;

        return this.getOperationSuiviCommercialisation;
      },
      async createTrancheDemande(
        trancheDemandeCreationRequest: TrancheDemandeCreationRequest[]
      ): Promise<void> {
        await commercialisationApi.createTrancheDemande(
          trancheDemandeCreationRequest
        );
        await this.fetchOperationSuiviCommercialisation(
          useOperationStore().getCurrentOperationId
        );
      },
    },
  }
);
