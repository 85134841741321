import { SocieteSupportDocument } from "@domain/dto/societeSupportDocument";
import { SocieteSupportDocumentWithPreviousVersions } from "@/domain/societeSupportDocumentWithPreviousVersions";
import groupBy from "lodash/groupBy";
import maxBy from "lodash/maxBy";
import orderBy from "lodash/orderBy";
import { SortingOrder } from "@domain/enum/sortingOrder";

export function getSocieteSupportDocumentListLatestVersion(
   
  societeSupportDocumentList: SocieteSupportDocument[]
): SocieteSupportDocumentWithPreviousVersions[] {
  const latestDocumentList: SocieteSupportDocumentWithPreviousVersions[] = [];

  const groupedSocieteSupportDocuments = groupBy(
    societeSupportDocumentList,
    (document) => {
      return document.name + "." + document.category;
    }
  );

   
  for (const [_key, documentList] of Object.entries(
    groupedSocieteSupportDocuments
  )) {
    const latestDocument = maxBy(
      documentList,
      (value: SocieteSupportDocument) => value.createdAt
    );
    const previousDocumentList = documentList.filter(
      (document) => document.id !== latestDocument?.id
    );

    latestDocumentList.push({
      ...(latestDocument as SocieteSupportDocument),
      previousVersionDocumentList: orderBy(
        previousDocumentList,
        (doc) => doc.createdAt,
        SortingOrder.DESCENDING
      ),
    });
  }

  return latestDocumentList;
}
