<template>
  <div class="modal-edit-document">
    <div class="cancel-button">
      <img
        alt="closeEditModal modal"
        data-test="cancel-button"
        src="@/assets/cross.svg"
        @click="closeWithBrouillon"
      />
    </div>
    <div class="pdf-viewer-container">
      <iframe
        ref="collaboraFrame"
        :src="editingDocumentLink"
        allow="fullscreen"
        class="edit-frame"
        lang="fr"
        title="pdf-viewer-frame"
      ></iframe>
    </div>
    <ConfirmModal
      v-if="showValidationModal"
      :loading="false"
      :message="modalMessage"
      title="Publier une nouvelle version"
      @cancelled="closeValidationModal"
      @validated="persistDocument"
    />
    <InactiviteEditDocumentModal
      v-if="showInactiviteModal"
      @validated="resetInactiviteTimer"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { NeomiEvent } from "@/domain/neomiEvent";
import { CollaboraApplication } from "@/utils/collaboraApplication";
import ConfirmModal from "@/components/shared/ConfirmModal.vue";
import { minutesToMilliseconds } from "date-fns";
import InactiviteEditDocumentModal from "@/components/document/InactiviteEditDocumentModal.vue";
import config from "@/config";

const INTERVAL_NOUVEAU_BR0UILLON_MINUTES = 10;

export default defineComponent({
  name: "EditDocumentModal",
  components: {
    InactiviteEditDocumentModal,
    ConfirmModal,
  },
  props: {
    modalMessage: {
      type: String,
      required: true,
    },
    editingDocumentLink: {
      type: String,
      required: true,
    },
  },
  setup(_props, context) {
    let collaboraApplication: CollaboraApplication | null = null;
    let intervalInactivite = 0;

    const hasInactivityTimer =
      config.INACTIVITE_INTERVAL !== 0 && config.INACTIVITE_CLOSE_TIMEOUT !== 0;

    const collaboraFrame = ref<HTMLFormElement>();
    const showValidationModal = ref<boolean>(false);
    const showInactiviteModal = ref<boolean>(false);

    const intervalBrouillon = window.setInterval(() => {
      context.emit(
        NeomiEvent.BROUILLON_DOCUMENT_INTERVAL,
        collaboraApplication
      );
    }, minutesToMilliseconds(INTERVAL_NOUVEAU_BR0UILLON_MINUTES));

    onMounted(async () => {
      if (hasInactivityTimer) {
        intervalInactivite = setInactiviteInterval();
      }

      if (collaboraFrame.value) {
        collaboraApplication = new CollaboraApplication(collaboraFrame.value);
        collaboraApplication.on(NeomiEvent.SAVE_EDITING_DOCUMENT, onSave);
        collaboraApplication.on(NeomiEvent.SAVED_EDITING_DOCUMENT, onSaved);
        collaboraApplication.on(NeomiEvent.EDIT_DOCUMENT_MODIFIED, onModified);
        context.emit(NeomiEvent.STARTED_EDITING_DOCUMENT);
      }
    });

    onBeforeUnmount(() => {
      if (collaboraApplication) {
        collaboraApplication.close();
        collaboraApplication.removeAllListeners();
      }
      if (intervalInactivite !== 0) {
        clearInterval(intervalInactivite);
      }
      clearInterval(intervalBrouillon);
    });

    function onModified(): void {
      context.emit(NeomiEvent.EDIT_DOCUMENT_MODIFIED);
    }

    function onSave(): void {
      showValidationModal.value = true;
    }

    function onSaved(): void {
      closeEditModal();
    }

    function persistDocument(): void {
      showValidationModal.value = false;
      if (collaboraApplication) {
        collaboraApplication.persistDocument();
      }
    }

    function resetInactiviteTimer(): void {
      clearInterval(intervalInactivite);
      intervalInactivite = setInactiviteInterval();
      toggleInactiviteModal();
    }

    function toggleInactiviteModal(): void {
      showInactiviteModal.value = !showInactiviteModal.value;
    }

    function closeValidationModal(): void {
      showValidationModal.value = false;
    }

    function closeWithBrouillon(): void {
      closeEditModal();
    }

    function closeEditModal(): void {
      context.emit(NeomiEvent.CLOSE_EDIT_DOCUMENT_MODAL, collaboraApplication);
    }

    function setInactiviteInterval(): number {
      return window.setInterval(() => {
        toggleInactiviteModal();
        setInactiviteShutdownTimeout();
      }, minutesToMilliseconds(config.INACTIVITE_INTERVAL));
    }

    function setInactiviteShutdownTimeout(): void {
      window.setTimeout(() => {
        if (collaboraApplication && showInactiviteModal.value) {
          toggleInactiviteModal();
          collaboraApplication.saveBrouillon();
          closeEditModal();
        }
      }, minutesToMilliseconds(config.INACTIVITE_CLOSE_TIMEOUT));
    }

    return {
      collaboraFrame,
      showValidationModal,
      showInactiviteModal,
      persistDocument,
      closeValidationModal,
      resetInactiviteTimer,
      closeWithBrouillon,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.modal-edit-document {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 499;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  overflow: auto;

  .pdf-viewer-container {
    display: flex;
    background-color: $white;
    width: calc(96% - 50px);
    margin: 2% auto;
    overflow-y: auto;
    height: 100vh;
    border-radius: 16px;
    border: 2px solid $primary-main;

    iframe {
      width: 100%;
      border: none;
    }
  }
}

.cancel-button {
  position: fixed;
  top: 12px;
  right: 12px;
  z-index: 2;

  img {
    height: 48px;
    width: 48px;
    cursor: pointer;
    transition: all linear 0.1s;

    &:hover {
      height: 56px;
      width: 56px;
    }
  }
}
</style>
