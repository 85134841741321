import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { TiersUserUpdateRequest } from "@domain/dto/tiersUserUpdateRequest";
import tiersApi from "@/api/tiersApi";
import { useOperationStore } from "@/store/operationModule.pinia";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import { useUserOperationStore } from "@/store/userOperationModule.pinia";
import { usePanelStore } from "@/store/panelModule.pinia";
import { TiersUserCreationRequest } from "@domain/dto/tiersUserCreationRequest";
import { UserCreationStatus } from "@domain/dto/userCreationStatus";
import { CreationStatus } from "@domain/enum/creationStatus";

export const useTiersStore = defineStore(ModuleType.Tiers, {
  actions: {
    async addTiers(
      tiersList: TiersUserCreationRequest[],
      documentList: string[]
    ): Promise<UserCreationStatus[] | void> {
      const idOperation = useOperationStore().getOperation.id;

      let response;
      try {
        response = await tiersApi.addTiers(
          tiersList,
          documentList,
          idOperation
        );
      } catch (error) {
        console.debug(error);
        toastError("Une erreur est survenue lors de l'invitation des tiers");
        return;
      }

      if (response.status === 201) {
        const successfulAdd = response.data.find(
          (status) => status.status === CreationStatus.CREATION_SUCCESS
        );
        const alreadyExistsStatus = response.data.find(
          (status) => status.status === CreationStatus.ALREADY_EXISTS
        );
        const failedStatus = response.data.find(
          (status) => status.status === CreationStatus.CREATION_FAILED
        );
        const userWithBanqueAlreadyInApp = response.data.find(
          (status) =>
            status.status === CreationStatus.OUT_OF_NEOMI_BANQUE_MATCHED
        );

        if (alreadyExistsStatus) {
          toastWarn(
            "Certaines invitations n’ont pas pu être envoyées, ces tiers sont déjà invités sur cette opération"
          );
        } else if (successfulAdd && !alreadyExistsStatus && !failedStatus) {
          toastSuccess("Vos invitations ont été envoyées avec succès");
        } else if (!successfulAdd && !alreadyExistsStatus && failedStatus) {
          toastError(
            "Une erreur s’est produite lors de l’envoi des invitations. Veuillez réessayer plus tard."
          );
        }

        if (userWithBanqueAlreadyInApp) {
          toastError(
            "Certains tiers n'ont pas pu être invités car ils sont membre d'une banque non adhérente."
          );
        }

        await useUserOperationStore().fetchOperationMembers(idOperation);
        return response.data;
      }
    },
    async updateTiersUser(
      tiersUpdateRequest: TiersUserUpdateRequest
    ): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      await tiersApi
        .updateTiersUser(tiersUpdateRequest, idOperation)
        .then(async (response) => {
          if (response.status === 200) {
            toastSuccess("Mise a jour effectué avec succès");
            await useUserOperationStore().fetchOperationMembers(idOperation);
            usePanelStore().CloseSidePanel();
          } else {
            toastError("Erreur lors de la modification de l'utilisateur");
          }
        })
        .catch(() => {
          toastError("Erreur lors de la modification de l'utilisateur");
        });
    },
    async resendEmailInvitation(payload: {
      tiersId: string;
      tiersEmail: string;
    }): Promise<void> {
      return tiersApi
        .resendEmailInvitation(payload.tiersId)
        .then(() =>
          toastSuccess(
            `Une invitation a été renvoyée à l'adresse ${payload.tiersEmail}`
          )
        )
        .catch(() =>
          toastError(
            `Une erreur est survenue lors du renvoi de l'invitation à l'adresse ${payload.tiersEmail}`
          )
        );
    },
  },
});
