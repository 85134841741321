import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { SocieteSupportLightWithAccessType } from "@domain/dto/societeSupportLightWithAccessType";
import { SocieteSupport } from "@/domain/societeSupport";
import { SocieteSupportFilters } from "@/domain/societeSupportFilters";
import { SocieteSupportType } from "@domain/enum/societeSupportType";
import { AccessType } from "@/domain/enum/accessType";
import { SocieteSupportLight } from "@domain/dto/societeSupportLight";
import { normalizeString } from "@/utils/wordUtils";
import { SocieteSupportCreationRequest } from "@domain/dto/societeSupportCreationRequest";
import { SocieteSupportFormErrorType } from "@/domain/enum/societeSupportFormErrorType";
import societeSupportApi from "@/api/societeSupportApi";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { SocieteSupportUpdateRequest } from "@domain/dto/societeSupportUpdateRequest";
import { SureteLight } from "@/domain/sureteLight";
import { OperationLight } from "@/domain/dto/operationLight";
import { SocieteSupportCMPCreate } from "@/domain/societeSupportCMPCreate";
import operationApi from "@/api/operationApi";
import { GroupLite } from "@domain/dto/groupLite";

type SocieteSupportStateType = {
  societeSupportLightList: SocieteSupportLightWithAccessType[];
  societeSupport: SocieteSupport;

  societeSupportOperations: OperationLight[];
  societeSupportImplicitOperations: OperationLight[];
  societeSupportFilters: SocieteSupportFilters;
  societeSupportCmpCreateList: SocieteSupportCMPCreate[];
  societeSupportChildrenBanqueList: GroupLite[];
};
export const useSocieteSupportStore = defineStore(ModuleType.SocieteSupport, {
  state: (): SocieteSupportStateType => ({
    societeSupportLightList: [],
    societeSupport: {
      id: "",
      idPromoteur: "",
      creationInProgress: false,
      name: "",
      siren: "",
      type: SocieteSupportType.FILIALE,
      accessType: AccessType.NORMAL,
      promoteur: {
        id: "",
        name: "",
        roles: [],
        users: [],
        monoSignaturePromoteur: [],
        doubleSignaturePromoteur: [],
        promoteursSignatairesGfa: [],
        reportingColor: "",
        logoColor: "",
        idLogoGroup: "",
      },
    },
    societeSupportOperations: [],
    societeSupportImplicitOperations: [],
    societeSupportFilters: { query: "" },
    societeSupportCmpCreateList: [],
    societeSupportChildrenBanqueList: [],
  }),
  getters: {
    getSocietesSupportLight(state): SocieteSupportLight[] {
      return state.societeSupportLightList;
    },

    getSocieteSupport(state): SocieteSupport {
      return state.societeSupport;
    },

    hasSocieteSupportReady(state): boolean {
      return !!state.societeSupport.id && state.societeSupport.id !== "";
    },

    getSocieteSupportOperations(state): OperationLight[] {
      return state.societeSupportOperations;
    },

    getSocieteSupportOperationsWithImplicits(state): OperationLight[] {
      return [
        ...state.societeSupportOperations,
        ...state.societeSupportImplicitOperations,
      ];
    },

    getSearchedSocieteSupportList(state): SocieteSupportLightWithAccessType[] {
      if (state.societeSupportFilters.query) {
        return state.societeSupportLightList.filter((societeSupport) => {
          return normalizeString(societeSupport.name).includes(
            normalizeString(state.societeSupportFilters.query)
          );
        });
      } else {
        return state.societeSupportLightList;
      }
    },
    getSocieteSupportCmpCreateList(state): SocieteSupportCMPCreate[] {
      return state.societeSupportCmpCreateList;
    },

    getSocieteSupportChildrenBanqueList(state): GroupLite[] {
      return state.societeSupportChildrenBanqueList;
    },
  },
  actions: {
    SetSocietesSupportLight(
      societesSupportLight: SocieteSupportLightWithAccessType[]
    ): void {
      this.societeSupportLightList = societesSupportLight;
    },

    SetSocieteSupport(societeSupport: SocieteSupport): void {
      this.societeSupport = societeSupport;
    },
    SetSocieteSupportCMPCreateList(
      SocieteSupportCMPCreateList: SocieteSupportCMPCreate[]
    ): void {
      this.societeSupportCmpCreateList = SocieteSupportCMPCreateList;
    },
    ResetSocieteSupport(): void {
      this.societeSupport = {
        id: "",
        idPromoteur: "",
        creationInProgress: false,
        name: "",
        siren: "",
        type: SocieteSupportType.FILIALE,
        accessType: AccessType.NORMAL,
        promoteur: {
          id: "",
          name: "",
          roles: [],
          users: [],
          monoSignaturePromoteur: [],
          doubleSignaturePromoteur: [],
          promoteursSignatairesGfa: [],
          reportingColor: "",
          logoColor: "",
          idLogoGroup: "",
        },
      };
      this.societeSupportOperations = [];
      this.societeSupportImplicitOperations = [];
    },
    SetSocieteSupportOperations(operations: OperationLight[]): void {
      this.societeSupportOperations = operations.filter((operation) => {
        const idSocieteSupportList = operation.demandeList.map(
          (demande) => demande.societeSupport.id
        );
        return idSocieteSupportList.includes(this.societeSupport.id);
      });
      this.societeSupportImplicitOperations = operations.filter((operation) => {
        const idSocieteSupportList = operation.demandeList.map(
          (demande) => demande.societeSupport.id
        );
        return !idSocieteSupportList.includes(this.societeSupport.id);
      });
    },

    SetSocieteSupportSearchQuery(searchQuery: string): void {
      this.societeSupportFilters.query = searchQuery;
    },

    ResetSocieteSupportSearchQuery(): void {
      this.societeSupportFilters.query = "";
    },

    SetSocieteSupportChildrenBanqueList(
      societeSupportChildrenBanqueList: GroupLite[]
    ): void {
      this.societeSupportChildrenBanqueList = societeSupportChildrenBanqueList;
    },

    async createSocieteSupport(
      societeSupport: SocieteSupportCreationRequest
    ): Promise<SocieteSupportFormErrorType[]> {
      return societeSupportApi
        .create(societeSupport)
        .then(async (response) => {
          if (response.status === 201) {
            toastSuccess("La société support a bien été créée");
            await this.fetchAllSocietesSupport(true);
            return [];
          } else if (response.status === 409) {
            toastError(
              "Certains champs sont en erreur et nécessitent d'être modifiés"
            );
            return response.data as SocieteSupportFormErrorType[];
          } else {
            toastError(
              "Une erreur est survenue lors de la création de la société support"
            );
            return [];
          }
        })
        .catch(() => {
          toastError(
            "Une erreur est survenue lors de la création de la société support"
          );
          return [];
        });
    },

    async fetchAllSocietesSupport(force?: boolean): Promise<void> {
      // Optimisation : on ne fait pas de requête si on a déjà les sociétés support
      if (this.societeSupportLightList.length > 0 && !force) {
        return;
      }
      const response = await societeSupportApi.fetchAll();
      this.SetSocietesSupportLight(response.data);
    },

    async fetchSocieteSupport(id: string): Promise<void> {
      this.ResetSocieteSupport();
      await societeSupportApi
        .fetchById(id)
        .then((response) => this.SetSocieteSupport(response.data));
    },

    async fetchAllSocieteSupportOperations(
      id: string,
      options: {
        withImplicitsOperations?: boolean;
      } = {}
    ): Promise<void> {
      const response = await societeSupportApi.fetchAllSocieteSupportOperations(
        id,
        {
          withImplicitOperations: options.withImplicitsOperations,
        }
      );
      this.SetSocieteSupportOperations(response.data);
    },

    async updateSocieteSupport(
      idSocieteSupport: string,
      societeSupportUpdateRequest: SocieteSupportUpdateRequest
    ): Promise<void> {
      societeSupportApi
        .update(idSocieteSupport, societeSupportUpdateRequest)
        .then((response) => {
          if (response.status === 200) {
            this.SetSocieteSupport(response.data as SocieteSupport);
            toastSuccess("Modification effectuée avec succès !");
            this.fetchAllSocietesSupport(true);
          } else if (response.status === 409) {
            const errorType = response.data as SocieteSupportFormErrorType[];
            if (errorType.includes(SocieteSupportFormErrorType.EXISTING_NAME)) {
              toastError(
                "Ce nom est déjà utilisé par une autre société support."
              );
            } else {
              toastError(
                "Une erreur est survenue lors de la modification de la société support"
              );
            }
          } else {
            toastError(
              "Une erreur est survenue lors de la modification de la société support"
            );
            return [];
          }
        })
        .catch(() => {
          toastError(
            "Une erreur s'est produite lors de la modification de la société support"
          );
        });
    },

    async fetchAllSurete(): Promise<SureteLight[]> {
      return societeSupportApi
        .fetchAllSurete()
        .then((response) => response.data)
        .catch(() => []);
    },
    async fetchSocieteSupportByIdOperationAndSocieteSupportIdList(
      idOperation: string,
      idSocieteSupportList: string[]
    ): Promise<void> {
      const response =
        await operationApi.fetchSocieteSupportByOperationIdAndSocieteSupportIdList(
          idOperation,
          idSocieteSupportList
        );

      this.SetSocieteSupportCMPCreateList(
        response.data.map((societeSupport) => ({
          name: societeSupport.name,
          id: societeSupport.id,
        }))
      );
    },
    async fetchChildrenBanqueListBySocieteSupport(
      idSocieteSupport: string
    ): Promise<void> {
      const response =
        await societeSupportApi.fetchChildrenBanqueListBySocieteSupport(
          idSocieteSupport
        );

      this.SetSocieteSupportChildrenBanqueList(response.data);
    },
    async fetchChildrenBanqueListBySocieteSupportAndOperation(
      idSocieteSupport: string,
      idOperation: string
    ): Promise<void> {
      const response =
        await societeSupportApi.fetchChildrenBanqueListBySocieteSupportAndOperation(
          idSocieteSupport,
          idOperation
        );

      this.SetSocieteSupportChildrenBanqueList(response.data);
    },
  },
});
