<template>
  <div class="cb-container" data-test="checkbox">
    <label>
      <input :checked="value" type="checkbox" @change="check($event)" />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script lang="ts">
import { NeomiEvent } from "@/domain/neomiEvent";

import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    function check(event: Event): void {
      const target = <HTMLInputElement>event.target;
      if (target) {
        context.emit(NeomiEvent.CHECK, {
          idCheck: props.id,
          value: target.checked,
        });
      }
    }

    return {
      check,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.cb-container {
  height: 24px;
  width: 24px;

  .checkmark {
    height: 24px;
    width: 24px;
  }

  .checkmark:after {
    height: 7px;
    left: 5px;
    top: 5px;
    width: 11px;
    border-width: 3px 3px 0 0;
  }
}
</style>
