<template>
  <div class="banner-specific-container">
    <div class="icon-container">
      <img
        alt="icône ajouter document"
        class="banner-icon"
        src="@/assets/event/NewDemand.svg"
      />
    </div>
    <div class="content mini">
      Des documents ont été ajoutés à la demande de mainlevée
      <span class="semi-bold">{{ notification.payload.demandeName }}</span>
      de l'opération
      <span class="semi-bold">{{ notification.payload.operationName }}</span>
      .
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import { toCategory } from "@/utils/notificationUtils";

import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    notification: {
      type: Object as () => Notification,
      required: true,
    },
  },
  setup(props) {
    const demandeCategory = computed((): string => {
      const demandeType = props.notification.payload.demandeType;
      return demandeType ? toCategory(demandeType) : "inconnu";
    });

    return {
      demandeCategory,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;
@use "@/sass/notification.scss" as *;
.banner-specific-container {
  display: flex;
}
</style>
