import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { UserCreationStatus } from "@domain/dto/userCreationStatus";
import { CreationStatus } from "@domain/enum/creationStatus";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import { HommeDeArtCreationRequest } from "@domain/dto/hommeDeArtCreationRequest";
import mainleveeApi from "@/api/mainleveeApi";
import { useDemandeStore } from "@/store/demandeModule.pinia";
import { useOperationStore } from "@/store/operationModule.pinia";

export const useMainleveeStore = defineStore(ModuleType.Mainlevee, {
  actions: {
    async addHommeDeArt(
      hommeDeArtCreationRequest: HommeDeArtCreationRequest
    ): Promise<UserCreationStatus | void> {
      const idDemande = useDemandeStore().getIdDemandeALever;
      const idOperation = useOperationStore().getOperation.id;
      return mainleveeApi
        .addHommeDeArt(hommeDeArtCreationRequest, idOperation, idDemande)
        .then(async (response) => {
          if (response.status === 201) {
            if (response.data.status === CreationStatus.ALREADY_EXISTS) {
              toastWarn("L'utilisateur existe deja.");
            } else if (
              response.data.status === CreationStatus.CREATION_SUCCESS
            ) {
              toastSuccess(
                `Invitations envoyée à ${hommeDeArtCreationRequest.email} avec succès.`
              );
            } else if (
              response.data.status === CreationStatus.CREATION_FAILED
            ) {
              toastError(
                "Une erreur s’est produite lors de l’envoi de l'invitation. Veuillez réessayer plus tard."
              );
            }
            return response.data;
          } else if (response.status == 409) {
            toastError(
              "L'utilisateur que vous cherchez à créer n'est pas un homme de l'art."
            );
          }
        })
        .catch(() =>
          toastError(
            "Une erreur est survenue lors de l'invitation de l'homme de l'art."
          )
        );
    },
    async validateMainLevee(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      mainleveeApi
        .validateMainlevee(payload.idOperation, payload.idDemande)
        .then((response) => {
          if (response?.status === 200) {
            toastSuccess("Mainlevée validée");
            const demandeStore = useDemandeStore();
            demandeStore.fetchDemande({
              idOperation: payload.idOperation,
              idDemande: demandeStore.getDemande.id,
            });
          } else {
            toastError("Échec lors de la validation de la mainlevée.");
            throw new Error();
          }
        });
    },

    async refuseMainLevee(payload: {
      idOperation: string;
      idDemande: string;
      demandeComplementaire: string;
    }): Promise<void> {
      try {
        const response = await mainleveeApi.updateDemandeComplementaire(
          payload.idOperation,
          payload.idDemande,
          payload.demandeComplementaire
        );
        if (response.status === 201) {
          useDemandeStore().SetDemande(response.data);
        }
      } catch (error) {
        console.debug(error);
        toastError("Échec lors de la validation de la mainlevée.");
      }
    },
  },
});
