import { SuiviTravauxMassifOperation } from "@domain/dto/suiviTravauxMassif";
import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import suivisTravauxMassifApi from "@/api/suiviTravauxMassifApi";
import { SuiviTravauxMassifSearchRequest } from "@domain/dto/suiviTravauxMassifSearchRequest";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { SuiviTravauxMassifCreateRequest } from "@domain/dto/suiviTravauxMassifCreateRequest";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { Buffer } from "buffer";
import { GroupTreeLite } from "@domain/dto/groupTreeLite";
import { SelectedSuiviTravauxFilter } from "@/domain/selectedSuiviTravauxFilter";
import { SuiviTravauxFilter } from "@domain/dto/suiviTravauxFilter";
import { ThrottleResult } from "@/apiRequest";

export const PAGE_SIZE = 20;

interface SuiviTravauxStateType {
  suivisTravaux: SuiviTravauxMassifOperation[];
  createRequestList: SuiviTravauxMassifCreateRequest[];
  lastUpdatedDate: Date | null;
  idGroupFilter: string;
  suiviTravauxFilter: SuiviTravauxFilter;
  selectedSuiviTravauxFilter: SelectedSuiviTravauxFilter;
}

export const useSuiviTravauxMassifStore = defineStore(
  ModuleType.SuiviTravauxMassif,
  {
    state: (): SuiviTravauxStateType => ({
      suivisTravaux: [],
      createRequestList: [],
      lastUpdatedDate: null,
      idGroupFilter: "0",
      suiviTravauxFilter: {
        partnerIdentityTreeList: [],
      },
      selectedSuiviTravauxFilter: {
        partnerIdList: [],
        searchQuery: "",
      },
    }),
    getters: {
      getSuiviTravaux(state): SuiviTravauxMassifOperation[] {
        return state.suivisTravaux;
      },
      getCreateRequestList(state): SuiviTravauxMassifCreateRequest[] {
        return state.createRequestList;
      },
      getSuiviTravauxSearchQuery(state): string {
        return state.selectedSuiviTravauxFilter.searchQuery;
      },
      getSuiviTravauxMassifLastUpdatedDate(state): Date | null {
        return state.lastUpdatedDate;
      },
      getPartnerIdentityTreeList(state): GroupTreeLite[] {
        return state.suiviTravauxFilter.partnerIdentityTreeList;
      },
      getIdGroupFilter(state): string {
        return state.idGroupFilter;
      },
      getSelectedSuiviTravauxFilter(state): SelectedSuiviTravauxFilter {
        return state.selectedSuiviTravauxFilter;
      },
    },
    actions: {
      SetCreateRequestList(
        createRequestList: SuiviTravauxMassifCreateRequest[]
      ): void {
        this.createRequestList = createRequestList;
      },
      setSuiviTravauxMassifSearchQuery(searchQuery: string): void {
        this.selectedSuiviTravauxFilter.searchQuery = searchQuery;
      },
      setSuiviTravauxMassifLastUpdatedDate(date: Date | null): void {
        this.lastUpdatedDate = date;
      },
      SetIdGroupFilter(idGroup: string): void {
        this.idGroupFilter = idGroup;
      },

      ResetCreateRequestList(): void {
        this.createRequestList = [];
      },

      ResetSelectedPartners(): void {
        this.selectedSuiviTravauxFilter.partnerIdList = [];
      },

      InitSuiviTravaux(suivisTravaux: SuiviTravauxMassifOperation[]): void {
        this.suivisTravaux = suivisTravaux;
      },

      AddSuiviTravaux(suivisTravaux: SuiviTravauxMassifOperation[]): void {
        this.suivisTravaux = [...this.suivisTravaux, ...suivisTravaux];
      },

      AddSelectedPartner(idPartner: string): void {
        this.selectedSuiviTravauxFilter.partnerIdList.push(idPartner);
      },

      InitializeSuiviTravauxFilter(filter: SuiviTravauxFilter): void {
        this.suiviTravauxFilter = filter;
      },

      async fetchSuiviTravaux(page: number): Promise<void> {
        const filters = this.getSelectedSuiviTravauxFilter;
        const searchRequest: SuiviTravauxMassifSearchRequest = {
          page,
          pageSize: PAGE_SIZE,
          searchQuery: this.selectedSuiviTravauxFilter.searchQuery || "",
          selectedIdGroupList: filters.partnerIdList,
        };

        try {
          const response = await suivisTravauxMassifApi.fetchAll(searchRequest);
          const suivis = response.data;

          if (page === 0) {
            this.InitSuiviTravaux(suivis);
          } else {
            this.AddSuiviTravaux(suivis);
          }
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === ThrottleResult.THROTTLED) {
              console.error(error);
              return;
            }

            toastError("Erreur au chargement des suivis de travaux");
          }
        }
      },

      async uploadSuiviTravauxMassifData(
        createRequestList: SuiviTravauxMassifCreateRequest[]
      ): Promise<void> {
        try {
          const response =
            await suivisTravauxMassifApi.uploadSuiviTravauxMassifData(
              createRequestList
            );

          if (response.data) {
            const { suivisOperationList, lineProcessed } = response.data;
            this.InitSuiviTravaux(suivisOperationList);
            toastSuccess(
              `${lineProcessed} lignes de suivi des travaux ont été mises à jour.`
            );
          }
        } catch (error) {
          console.debug(error);
          toastError("Erreur lors de l'envoi des suivis de travaux");
        }
      },

      async fetchSuiviTravauxMassifExport(): Promise<void> {
        const response =
          await suivisTravauxMassifApi.fetchSuiviTravauxMassifExport();

        const buffer = Buffer.from(response.data as string, "base64");
        downloadClickLink(buffer, {
          name: "export_suivi_travaux.xlsx",
          type: NemmoMimeType.EXCEL,
        });
      },
      async computeLastUpdatedDate(): Promise<void> {
        try {
          const allDates = this.suivisTravaux
            .map((suiviTravaux) => {
              return suiviTravaux.suivis
                .map((suivi) => suivi.createdAt)
                .filter((el) => !!el);
            })
            .flat();
          let mostRecentSuiviTravaux: number = -Infinity;
          for (const date of allDates) {
            if (date && new Date(date).getTime() > mostRecentSuiviTravaux) {
              mostRecentSuiviTravaux = new Date(date).getTime();
            }
          }
          this.setSuiviTravauxMassifLastUpdatedDate(
            new Date(mostRecentSuiviTravaux)
          );
        } catch (error) {
          console.debug(error);
        }
      },

      async fetchSuiviTravauxFilters(): Promise<void> {
        const response =
          await suivisTravauxMassifApi.fetchSuiviTravauxFilters();

        if (response.data) {
          this.InitializeSuiviTravauxFilter(response.data);
        }
      },

      async updateIdGroupFilter(idGroup: string): Promise<void> {
        this.ResetSelectedPartners();

        if (idGroup !== "0") {
          this.AddSelectedPartner(idGroup);
        }

        this.SetIdGroupFilter(idGroup);
        await this.fetchSuiviTravaux(0);
      },
    },
  }
);
