import { Group } from "@domain/dto/group";
import { GroupTreeHeavy } from "@/domain/dto/groupTreeHeavy";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { Role } from "@domain/enum/role";
import { User } from "@domain/dto/user";
import {
  getGroupIdFromUser,
  getUserGroupFromOperation,
} from "@/utils/userUtils";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { OperationHeavy } from "@/domain/operationHeavy";
import { OperationAccessAsRole } from "@/domain/enum/operationAccessAsRole";
import { OperationMessageCanal } from "@domain/enum/operationMessageCanal";
import { BanqueForPetitPromoteur } from "@domain/dto/banquePetitPromoteur";
import { GroupLite } from "@domain/dto/groupLite";
import { SortingOrder } from "@domain/enum/sortingOrder";

const OPERATION_ACCESS_TO_ROLE: (
  canal: OperationMessageCanal
) => Map<OperationAccessAsRole, Role> = (canal) => {
  return new Map([
    [OperationAccessAsRole.PROMOTEUR, Role.PROMOTEUR],
    [OperationAccessAsRole.BANQUE, Role.BANQUE],
    [
      OperationAccessAsRole.PARTICIPANT_AND_BANQUE,
      canal === OperationMessageCanal.POOL ? Role.POOL : Role.BANQUE,
    ],
    [OperationAccessAsRole.PARTICIPANT, Role.POOL],
  ]);
};
export function hasRole(roles: string[], role: Role): boolean {
  return roles.includes(role);
}

export function buildGroupTreeHeavy(groupList: Group[]): GroupTreeHeavy[] {
  const parentList = groupList.filter((promoteur) => !promoteur.parent);
  return parentList.map((parent) => {
    return {
      ...parent,
      children: groupList.filter(
        (promoteur) => promoteur.parent?.id === parent.id
      ),
    };
  });
}

export function isEventDisabled(
  disabledNotificationGroups: string[],
  disabledEmailGroups: string[],
  idGroup: string
): boolean {
  return (
    isNotificationDisabled(disabledNotificationGroups, idGroup) &&
    isEmailDisabled(disabledEmailGroups, idGroup)
  );
}

function isNotificationDisabled(
  disabledNotificationGroups: string[],
  idGroup: string
): boolean {
  return (
    disabledNotificationGroups?.find(
      (disabledGroupId: string) => disabledGroupId === idGroup
    ) !== undefined
  );
}

function isEmailDisabled(
  disabledEmailGroups: string[],
  idGroup: string
): boolean {
  return (
    disabledEmailGroups?.find(
      (disabledGroupId: string) => disabledGroupId === idGroup
    ) !== undefined
  );
}

export function toggleElementFromList(
  groupIdList: string[],
  idGroup: string,
  shouldDeleteElement: boolean
): string[] {
  const newList = groupIdList.filter(
    (disabledGroupId: string) => disabledGroupId !== idGroup
  );
  if (!shouldDeleteElement) {
    newList.push(idGroup);
  }
  return newList;
}

export function getMasterGroupList(groupList: Group[]): Group[] {
  const parentGroupList = groupList
    .filter((group) => !!group.parent)
    .map((group) => group.parent as Group);
  const masterGroupList = groupList.filter((group) => !group.parent);
  return orderBy(
    uniqBy([...parentGroupList, ...masterGroupList], "id"),
    (item) => item.name
  );
}

export function getMasterBanqueGroupWithDisabled(
  groupList: BanqueForPetitPromoteur[]
): BanqueForPetitPromoteur[] {
  const parentGroupList = groupList
    .filter((group) => !!group.parent)
    .map((group) => group.parent as BanqueForPetitPromoteur);
  const masterGroupList = groupList.filter((group) => !group.parent);
  const masterBanqueList = orderBy(
    uniqBy([...parentGroupList, ...masterGroupList], "id"),
    (item) => item.name
  );

  const banqueList = masterBanqueList.map((banque): BanqueForPetitPromoteur => {
    const childrenList = groupList.filter(
      (group) => group.parent?.id === banque.id
    );

    if (childrenList.length === 0) {
      return banque;
    }

    return {
      ...banque,
      disabled: childrenList.every((group) => group.disabled),
    };
  });

  return orderBy(
    banqueList,
    [(banque) => banque.disabled],
    [SortingOrder.ASCENDING]
  );
}

export function getChildrenGroupListByMasterGroup<
  T extends Group | BanqueForPetitPromoteur,
>(groupList: T[], idMasterGroup: string): T[] {
  return groupList.filter((group) => group.parent?.id === idMasterGroup);
}

export function getGroupRole(roleList: string[]): Role {
  if (hasRole(roleList, Role.PROMOTEUR)) {
    return Role.PROMOTEUR;
  } else if (hasRole(roleList, Role.BANQUE)) {
    return Role.BANQUE;
  } else if (hasRole(roleList, Role.TIERS)) {
    return Role.TIERS;
  } else if (hasRole(roleList, Role.POOL)) {
    return Role.POOL;
  } else throw Error("Pas de role valide");
}

export function getGroupRoleFromUser(user: User): Role {
  return getGroupRole(user.roles);
}

export function getParentGroupIdListFromUserList(
  initialGroupId: string,
  users: AnnuaireUser[]
): Set<string> {
  const linkedGroupIdList = new Set([initialGroupId]);

  for (const member of users) {
    member.groups.forEach((group) => {
      if (linkedGroupIdList.has(group.id) && group.idGroupParent) {
        linkedGroupIdList.add(group.idGroupParent);
      }
    });
  }

  return linkedGroupIdList;
}

/** Extrait des utilisateurs la liste des idGroup dont le groupe parent est le groupe passé en paramètre */
export function getChildGroupIdListFromUserList(
  initialGroupId: string,
  users: AnnuaireUser[]
): Set<string> {
  const linkedGroupIdList = new Set([initialGroupId]);

  for (const member of users) {
    member.groups.forEach((group) => {
      if (group.idGroupParent && linkedGroupIdList.has(group.idGroupParent)) {
        linkedGroupIdList.add(group.id);
      }
    });
  }

  return linkedGroupIdList;
}

export function getMasterGroupId(user: User): string {
  const groupWithParent = user.groups.find((group) => !!group.parent);
  if (groupWithParent) {
    return groupWithParent.parent?.id as string;
  }
  return getGroupIdFromUser(user);
}

export function getMasterGroupIdFromOperation(
  user: User,
  operation: OperationHeavy
): string | undefined {
  const groupWithParent = user.groups.find((group) => !!group.parent);
  if (groupWithParent) {
    return groupWithParent.parent?.id;
  }
  return getUserGroupFromOperation(operation, user)?.id;
}

export function mapOperationAccessToRole(
  operationAccessType: OperationAccessAsRole,
  canal: OperationMessageCanal
): Role | undefined {
  return OPERATION_ACCESS_TO_ROLE(canal).get(operationAccessType);
}

export function flattenGroupIdList(groups: GroupLite[]): string[] {
  const idGroupList: string[] = [];
  groups.forEach((group) => {
    idGroupList.push(group.id);
    if (group.parent) {
      idGroupList.push(group.parent.id);
    }
  });
  return idGroupList;
}
