export enum SuiviBudgetLigneType {
  TERRAIN = "TERRAIN",
  AMENAGEMENT = "AMENAGEMENT",
  TRAVAUX = "TRAVAUX",
  HONORAIRES_TECHNIQUES = "HONORAIRES TECHNIQUES",
  FRAIS_ANNEXES_CONSTRUCTION = "FRAIS ANNEXES CONSTRUCTION",
  FRAIS_COMMERCIALISATION = "FRAIS COMMERCIALISATION",
  GESTION = "GESTION",
  FRAIS_FINANCIERS = "FRAIS FINANCIERS",
  RECETTES = "RECETTES",
}
