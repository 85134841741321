<template>
  <div
    class="notification-banner-container"
    data-test="redirect"
    @click="redirectUser"
  >
    <div class="banner-item">
      <div class="arrow-up"></div>
      <div class="banner">
        <NotificationBannerListItemUserMessageMention
          v-if="isUserMessageMentionNotification"
          :notification="notification"
        />
        <NotificationBannerListItemOperationJoined
          v-if="isUserJoiningOperationNotification"
          :notification="notification"
        />

        <NotificationBannerListItemCmpNewDocument
          v-else-if="isCmpNewDocumentNotification"
          :notification="notification"
        />

        <NotificationBannerListItemOperationDocumentUploaded
          v-else-if="isOperationDocumentUploadedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemDemandeSharedWithBanque
          v-else-if="isDemandeSharedWithBanqueNotification"
          :notification="notification"
        />
        <NotificationBannerListItemDemandeProjectUploaded
          v-else-if="isDemandeProjectUploadedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemDemandeProjectValidated
          v-else-if="isDemandeProjectValidatedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemDemandeSignMethodChosen
          v-else-if="isDemandeSignMethodChosenNotification"
          :notification="notification"
        />
        <NotificationBannerListItemDemandeSigned
          v-else-if="isDemandeSignedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemDemandeOutdated
          v-else-if="isDemandeOutdatedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemLettreAccordProjectUploaded
          v-else-if="isLettreAccordProjectUploadedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemLettreAccordValidated
          v-else-if="isLettreAccordValidatedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemLettreAccordSignReady
          v-else-if="isLettreAccordSignReadyNotification"
          :notification="notification"
        />
        <NotificationBannerListItemLettreAccordSigned
          v-else-if="isLettreAccordSignedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSureteCreated
          v-else-if="isSureteCreatedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSureteProjectUploaded
          v-else-if="isSureteProjectUploadedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSureteProjectValidated
          v-else-if="isSureteProjectValidatedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSureteSignReady
          v-else-if="isSureteSignReadyNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSureteSigned
          v-else-if="isSureteSignedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemCmpSureteSigned
          v-else-if="isCmpSureteSignedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemActeSecondaireCreated
          v-else-if="isActeSecondaireCreatedNotification"
          :notification="notification"
        />
        <div v-else-if="isOperationMessageSummaryExternalNotification">
          <NotificationBannerListItemOperationExternalMessageSummary
            v-if="
              notification.payload.messageExternalNotificationContent &&
              notification.payload.operationName
            "
            :message-external-notification-content="
              notification.payload.messageExternalNotificationContent
            "
            :operation-name="notification.payload.operationName"
          />
        </div>
        <div v-else-if="isOperationMessageSummaryInternalNotification">
          <NotificationBannerListItemOperationInternalMessageSummary
            v-if="
              notification.payload.messageInternalNotificationContent &&
              notification.payload.operationName
            "
            :message-internal-notification-content="
              notification.payload.messageInternalNotificationContent
            "
            :operation-name="notification.payload.operationName"
          />
        </div>
        <div v-else-if="isOperationMessageSummaryChefDeFileNotification">
          <NotificationBannerListItemOperationChefDeFileMessageSummary
            v-if="
              notification.payload.messageChefDeFileNotificationContent &&
              notification.payload.operationName
            "
            :message-chef-de-file-notification-content="
              notification.payload.messageChefDeFileNotificationContent
            "
            :operation-name="notification.payload.operationName"
          />
        </div>
        <div v-else-if="isOperationMessageSummaryPoolNotification">
          <NotificationBannerListItemOperationPoolMessageSummary
            v-if="
              notification.payload.messagePoolNotificationContent &&
              notification.payload.operationName
            "
            :message-pool-notification-content="
              notification.payload.messagePoolNotificationContent
            "
            :operation-name="notification.payload.operationName"
          />
        </div>
        <NotificationBannerListItemMainleveeRequest
          v-else-if="isMainleveeRequestNotification"
          :notification="notification"
        />
        <NotificationBannerListItemMainleveeDocumentUploaded
          v-else-if="isMainleveeDocumentUploadedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemMainleveeRefused
          v-else-if="isMainleveeRefusedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemMainleveeValidated
          v-else-if="isMainleveeValidatedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemPoolJoinDemande
          v-else-if="isPoolJoinDemandeNotification"
          :notification="notification"
        />

        <NotificationBannerListItemAccordCommercialProjetAjoute
          v-else-if="isAccordCommercialProjetAjouteNotification"
          :notification="notification"
        />
        <NotificationBannerListItemAccordCommercialProjetValide
          v-else-if="isAccordCommercialProjetValideNotification"
          :notification="notification"
        />
        <NotificationBannerListItemAccordCommercialProjetRefused
          v-else-if="isAccordCommercialProjetDeclinedNotification"
          :notification="notification"
        />
        <NotificationBannerListItemAccordCommercialProjetPretASigne
          v-else-if="isAccordCommercialProjetPretASigneNotification"
          :notification="notification"
        />
        <NotificationBannerListItemAccordCommercialProjetSigne
          v-else-if="isAccordCommercialProjetSigneNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSuiviTravauxEtapeDeclaration
          v-else-if="isSuiviTravauxEtapeDeclarationNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSuiviCommercialisationNewImport
          v-else-if="isSuiviCommercialisationNewImportNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSuiviCommercialisationEditionTranche
          v-else-if="isSuiviCommercialisationEditionTrancheNotification"
          :notification="notification"
        />
        <NotificationBannerListItemOperationArchivedByPartner
          v-else-if="isOperationArchivedByPartnerNotification"
          :notification="notification"
        />
        <NotificationBannerListItemESGNewVersion
          v-else-if="isEsgNouvelleVersionNotification"
          :notification="notification"
        />
        <NotificationBannerListItemEsgBanqueAskPromoteurForForm
          v-else-if="isEsgBanqueAskPromoteurForFormNotification"
          :notification="notification"
        />
        <NotificationBannerListItemOperationAutoArchive
          v-else-if="isOperationArchivageAutoNotification"
          :notification="notification"
        />
        <NotificationBannerListItemSuiviBudgetPromoteurImport
          v-else-if="isSuiviBudgetPromoteurImportNotification"
          :notification="notification"
        />
        <img
          alt="close"
          class="close-btn"
          src="../../assets/cross_orange.svg"
          @click.stop="close()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/domain/notification";
import { isOnCurrentPage } from "@/utils/updateStore";
import NotificationBannerListItemOperationJoined from "@/components/notification/NotificationBannerListItemOperationJoined.vue";
import NotificationBannerListItemDemandeSharedWithBanque from "@/components/notification/NotificationBannerListItemDemandeSharedWithBanque.vue";
import NotificationBannerListItemDemandeProjectUploaded from "@/components/notification/NotificationBannerListItemDemandeProjectUploaded.vue";
import NotificationBannerListItemDemandeProjectValidated from "@/components/notification/NotificationBannerListItemDemandeProjectValidated.vue";
import NotificationBannerListItemDemandeSignMethodChosen from "@/components/notification/NotificationBannerListItemDemandeSignMethodChosen.vue";
import NotificationBannerListItemDemandeSigned from "@/components/notification/NotificationBannerListItemDemandeSigned.vue";
import NotificationBannerListItemLettreAccordProjectUploaded from "@/components/notification/NotificationBannerListItemLettreAccordProjectUploaded.vue";
import NotificationBannerListItemLettreAccordValidated from "@/components/notification/NotificationBannerListItemLettreAccordValidated.vue";
import NotificationBannerListItemLettreAccordSignReady from "@/components/notification/NotificationBannerListItemLettreAccordSignReady.vue";
import NotificationBannerListItemLettreAccordSigned from "@/components/notification/NotificationBannerListItemLettreAccordSigned.vue";
import NotificationBannerListItemSureteCreated from "@/components/notification/NotificationBannerListItemSureteCreated.vue";
import NotificationBannerListItemSureteProjectUploaded from "@/components/notification/NotificationBannerListItemSureteProjectUploaded.vue";
import NotificationBannerListItemSureteProjectValidated from "@/components/notification/NotificationBannerListItemSureteProjectValidated.vue";
import NotificationBannerListItemSureteSignReady from "@/components/notification/NotificationBannerListItemSureteSignReady.vue";
import NotificationBannerListItemSureteSigned from "@/components/notification/NotificationBannerListItemSureteSigned.vue";
import NotificationBannerListItemOperationDocumentUploaded from "@/components/notification/NotificationBannerListItemOperationDocumentUploaded.vue";
import NotificationBannerListItemPoolJoinDemande from "@/components/notification/NotificationBannerListItemPoolJoinDemande.vue";
import NotificationBannerListItemUserMessageMention from "@/components/notification/NotificationBannerListItemUserMessageMention.vue";
import NotificationBannerListItemCmpNewDocument from "@/components/notification/NotificationBannerListItemCmpNewDocument.vue";
import NotificationBannerListItemCmpSureteSigned from "@/components/notification/NotificationBannerListItemCmpSureteSigned.vue";
import NotificationBannerListItemSuiviTravauxEtapeDeclaration from "@/components/notification/NotificationBannerListItemSuiviTravauxEtapeDeclaration.vue";
import NotificationBannerListItemOperationArchivedByPartner from "@/components/notification/NotificationBannerListItemOperationArchivedByPartner.vue";
import {
  isAccordCommercialProjetAjouteNotif,
  isAccordCommercialProjetPretASigneNotif,
  isAccordCommercialProjetSigneNotif,
  isAccordCommercialProjetValideNotif,
  isActeSecondaireCreatedNotif,
  isCmpSureteSigned,
  isCmpNewDocument,
  isDemandeOutdatedNotif,
  isDemandeProjectUploadedNotif,
  isDemandeProjectValidatedNotif,
  isDemandeSharedWithBanqueNotif,
  isDemandeSignedNotif,
  isDemandeSignMethodChosenNotif,
  isEsgBanqueAskPromoteurForForm,
  isEsgNouvelleVersionNotif,
  isSureteCreatedNotif,
  isSureteProjectUploadedNotif,
  isSureteProjectValidatedNotif,
  isSureteSignedNotif,
  isSureteSignReadyNotif,
  isLettreAccordProjectUploadedNotif,
  isLettreAccordSignedNotif,
  isLettreAccordSignReadyNotif,
  isLettreAccordValidatedNotif,
  isMainleveeDocumentUploadedNotif,
  isMainleveeRefusedNotif,
  isMainleveeRequestNotif,
  isMainleveeValidatedNotif,
  isOperationArchivageAuto,
  isOperationArchivedByPartner,
  isOperationChatSummaryNotif,
  isOperationDocumentUploadedNotif,
  isPoolJoinDemande,
  isSuiviCommercialisationNewImport,
  isSuiviTravauxEtapeDeclaration,
  isUserJoiningOperationNotif,
  isUserJoiningOperationWithNewPartnerNotif,
  isUserMessageMentionNotif,
  redirectFromNotification,
  isSuiviCommercialisationEditionTranche,
  isSuiviBudgetPromoteurImportNotif,
  isAccordCommercialProjetDeclinedNotif,
} from "@/utils/notificationUtils";
import NotificationBannerListItemOperationExternalMessageSummary from "@/components/notification/NotificationBannerListItemOperationExternalMessageSummary.vue";
import NotificationBannerListItemOperationInternalMessageSummary from "@/components/notification/NotificationBannerListItemOperationInternalMessageSummary.vue";
import NotificationBannerListItemActeSecondaireCreated from "@/components/notification/NotificationBannerListItemActeSecondaireCreated.vue";
import NotificationBannerListItemDemandeOutdated from "@/components/notification/NotificationBannerListItemDemandeOutdated.vue";

import { useRouter } from "vue-router";
import NotificationBannerListItemMainleveeRequest from "@/components/notification/NotificationBannerListItemMainleveeRequest.vue";
import NotificationBannerListItemMainleveeDocumentUploaded from "@/components/notification/NotificationBannerListItemMainleveeDocumentUploaded.vue";
import NotificationBannerListItemMainleveeValidated from "@/components/notification/NotificationBannerListItemMainleveeValidated.vue";
import NotificationBannerListItemMainleveeRefused from "@/components/notification/NotificationBannerListItemMainleveeRefused.vue";
import { computed, defineComponent, PropType } from "vue";
import { getSetupContext } from "@/utils/vueUtils";
import NotificationBannerListItemAccordCommercialProjetAjoute from "./NotificationBannerListItemAccordCommercialProjetAjoute.vue";
import NotificationBannerListItemAccordCommercialProjetValide from "./NotificationBannerListItemAccordCommercialProjetValide.vue";
import NotificationBannerListItemAccordCommercialProjetRefused from "./NotificationBannerListItemAccordCommercialProjetRefused.vue";
import NotificationBannerListItemAccordCommercialProjetPretASigne from "./NotificationBannerListItemAccordCommercialProjetPretASigne.vue";
import NotificationBannerListItemAccordCommercialProjetSigne from "./NotificationBannerListItemAccordCommercialProjetSigne.vue";
import NotificationBannerListItemOperationChefDeFileMessageSummary from "@/components/notification/NotificationBannerListItemOperationChefDeFileMessageSummary.vue";
import NotificationBannerListItemOperationPoolMessageSummary from "@/components/notification/NotificationBannerListItemOperationPoolMessageSummary.vue";
import NotificationBannerListItemESGNewVersion from "@/components/notification/NotificationBannerListItemESGNewVersion.vue";
import NotificationBannerListItemEsgBanqueAskPromoteurForForm from "./NotificationBannerListItemEsgBanqueAskPromoteurForForm.vue";
import NotificationBannerListItemSuiviCommercialisationNewImport from "./NotificationBannerListItemSuiviCommercialisationNewImport.vue";
import NotificationBannerListItemOperationAutoArchive from "@/components/notification/NotificationBannerListItemOperationAutoArchive.vue";
import NotificationBannerListItemSuiviCommercialisationEditionTranche from "@/components/notification/NotificationBannerListItemSuiviCommercialisationEditionTranche.vue";
import NotificationBannerListItemSuiviBudgetPromoteurImport from "@/components/notification/NotificationBannerListItemSuiviBudgetPromoteurImport.vue";

export default defineComponent({
  components: {
    NotificationBannerListItemESGNewVersion,
    NotificationBannerListItemOperationPoolMessageSummary,
    NotificationBannerListItemOperationChefDeFileMessageSummary,
    NotificationBannerListItemActeSecondaireCreated,
    NotificationBannerListItemOperationInternalMessageSummary,
    NotificationBannerListItemOperationExternalMessageSummary,
    NotificationBannerListItemUserMessageMention,
    NotificationBannerListItemOperationDocumentUploaded,
    NotificationBannerListItemOperationJoined,
    NotificationBannerListItemDemandeSharedWithBanque,
    NotificationBannerListItemDemandeProjectUploaded,
    NotificationBannerListItemDemandeProjectValidated,
    NotificationBannerListItemDemandeSignMethodChosen,
    NotificationBannerListItemDemandeSigned,
    NotificationBannerListItemLettreAccordProjectUploaded,
    NotificationBannerListItemLettreAccordValidated,
    NotificationBannerListItemLettreAccordSignReady,
    NotificationBannerListItemLettreAccordSigned,
    NotificationBannerListItemSureteCreated,
    NotificationBannerListItemSureteProjectUploaded,
    NotificationBannerListItemSureteProjectValidated,
    NotificationBannerListItemSureteSignReady,
    NotificationBannerListItemSureteSigned,
    NotificationBannerListItemDemandeOutdated,
    NotificationBannerListItemMainleveeRequest,
    NotificationBannerListItemMainleveeDocumentUploaded,
    NotificationBannerListItemMainleveeRefused,
    NotificationBannerListItemMainleveeValidated,
    NotificationBannerListItemPoolJoinDemande,
    NotificationBannerListItemCmpNewDocument,
    NotificationBannerListItemCmpSureteSigned,
    NotificationBannerListItemAccordCommercialProjetAjoute,
    NotificationBannerListItemAccordCommercialProjetValide,
    NotificationBannerListItemAccordCommercialProjetRefused,
    NotificationBannerListItemAccordCommercialProjetPretASigne,
    NotificationBannerListItemAccordCommercialProjetSigne,
    NotificationBannerListItemSuiviTravauxEtapeDeclaration,
    NotificationBannerListItemOperationArchivedByPartner,
    NotificationBannerListItemEsgBanqueAskPromoteurForForm,
    NotificationBannerListItemSuiviCommercialisationNewImport,
    NotificationBannerListItemSuiviCommercialisationEditionTranche,
    NotificationBannerListItemOperationAutoArchive,
    NotificationBannerListItemSuiviBudgetPromoteurImport,
  },
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  setup(props) {
    const { notificationStore } = getSetupContext();
    const router = useRouter();

    const isUserMessageMentionNotification = computed((): boolean => {
      return isUserMessageMentionNotif(props.notification.type);
    });

    const isUserJoiningOperationNotification = computed((): boolean => {
      return (
        isUserJoiningOperationNotif(props.notification.type) ||
        isUserJoiningOperationWithNewPartnerNotif(props.notification.type)
      );
    });

    const isOperationDocumentUploadedNotification = computed((): boolean => {
      return isOperationDocumentUploadedNotif(props.notification.type);
    });

    const isDemandeProjectUploadedNotification = computed((): boolean => {
      return isDemandeProjectUploadedNotif(props.notification.type);
    });

    const isDemandeProjectValidatedNotification = computed((): boolean => {
      return isDemandeProjectValidatedNotif(props.notification.type);
    });

    const isDemandeSignMethodChosenNotification = computed((): boolean => {
      return isDemandeSignMethodChosenNotif(props.notification.type);
    });

    const isDemandeSignedNotification = computed((): boolean => {
      return isDemandeSignedNotif(props.notification.type);
    });

    const isDemandeOutdatedNotification = computed((): boolean => {
      return isDemandeOutdatedNotif(props.notification.type);
    });

    const isDemandeSharedWithBanqueNotification = computed((): boolean => {
      return isDemandeSharedWithBanqueNotif(props.notification.type);
    });

    const isLettreAccordValidatedNotification = computed((): boolean => {
      return isLettreAccordValidatedNotif(props.notification.type);
    });

    const isLettreAccordProjectUploadedNotification = computed((): boolean => {
      return isLettreAccordProjectUploadedNotif(props.notification.type);
    });

    const isLettreAccordSignReadyNotification = computed((): boolean => {
      return isLettreAccordSignReadyNotif(props.notification.type);
    });

    const isLettreAccordSignedNotification = computed((): boolean => {
      return isLettreAccordSignedNotif(props.notification.type);
    });

    const isSureteCreatedNotification = computed((): boolean => {
      return isSureteCreatedNotif(props.notification.type);
    });

    const isSureteProjectUploadedNotification = computed((): boolean => {
      return isSureteProjectUploadedNotif(props.notification.type);
    });

    const isSureteProjectValidatedNotification = computed((): boolean => {
      return isSureteProjectValidatedNotif(props.notification.type);
    });

    const isSureteSignReadyNotification = computed((): boolean => {
      return isSureteSignReadyNotif(props.notification.type);
    });

    const isSureteSignedNotification = computed((): boolean => {
      return isSureteSignedNotif(props.notification.type);
    });

    const isActeSecondaireCreatedNotification = computed((): boolean => {
      return isActeSecondaireCreatedNotif(props.notification.type);
    });

    const isPoolJoinDemandeNotification = computed((): boolean => {
      return isPoolJoinDemande(props.notification.type);
    });

    const isCmpNewDocumentNotification = computed((): boolean => {
      return isCmpNewDocument(props.notification.type);
    });

    const isOperationMessageSummaryExternalNotification = computed(
      (): boolean => {
        return (
          isOperationChatSummaryNotif(props.notification.type) &&
          !!props.notification.payload.messageExternalNotificationContent
        );
      }
    );
    const isOperationMessageSummaryChefDeFileNotification = computed(
      (): boolean => {
        return (
          isOperationChatSummaryNotif(props.notification.type) &&
          !!props.notification.payload.messageChefDeFileNotificationContent
        );
      }
    );

    const isOperationMessageSummaryInternalNotification = computed(
      (): boolean => {
        return (
          isOperationChatSummaryNotif(props.notification.type) &&
          !!props.notification.payload.messageInternalNotificationContent
        );
      }
    );
    const isOperationMessageSummaryPoolNotification = computed((): boolean => {
      return (
        isOperationChatSummaryNotif(props.notification.type) &&
        !!props.notification.payload.messagePoolNotificationContent
      );
    });

    const isMainleveeRequestNotification = computed((): boolean =>
      isMainleveeRequestNotif(props.notification.type)
    );

    const isMainleveeDocumentUploadedNotification = computed((): boolean =>
      isMainleveeDocumentUploadedNotif(props.notification.type)
    );

    const isMainleveeRefusedNotification = computed((): boolean =>
      isMainleveeRefusedNotif(props.notification.type)
    );

    const isMainleveeValidatedNotification = computed((): boolean =>
      isMainleveeValidatedNotif(props.notification.type)
    );

    const isCmpSureteSignedNotification = computed((): boolean => {
      return isCmpSureteSigned(props.notification.type);
    });

    const isAccordCommercialProjetAjouteNotification = computed(() =>
      isAccordCommercialProjetAjouteNotif(props.notification.type)
    );
    const isAccordCommercialProjetValideNotification = computed(() =>
      isAccordCommercialProjetValideNotif(props.notification.type)
    );
    const isAccordCommercialProjetDeclinedNotification = computed(() =>
      isAccordCommercialProjetDeclinedNotif(props.notification.type)
    );
    const isAccordCommercialProjetPretASigneNotification = computed(() =>
      isAccordCommercialProjetPretASigneNotif(props.notification.type)
    );
    const isAccordCommercialProjetSigneNotification = computed(() =>
      isAccordCommercialProjetSigneNotif(props.notification.type)
    );
    const isSuiviTravauxEtapeDeclarationNotification = computed(() =>
      isSuiviTravauxEtapeDeclaration(props.notification.type)
    );
    const isSuiviCommercialisationNewImportNotification = computed(() =>
      isSuiviCommercialisationNewImport(props.notification.type)
    );
    const isSuiviCommercialisationEditionTrancheNotification = computed(() =>
      isSuiviCommercialisationEditionTranche(props.notification.type)
    );

    const isOperationArchivedByPartnerNotification = computed(() =>
      isOperationArchivedByPartner(props.notification.type)
    );

    const isEsgNouvelleVersionNotification = computed(() =>
      isEsgNouvelleVersionNotif(props.notification.type)
    );

    const isEsgBanqueAskPromoteurForFormNotification = computed(() =>
      isEsgBanqueAskPromoteurForForm(props.notification.type)
    );

    const isOperationArchivageAutoNotification = computed((): boolean => {
      return isOperationArchivageAuto(props.notification.type);
    });

    const isSuiviBudgetPromoteurImportNotification = computed((): boolean => {
      return isSuiviBudgetPromoteurImportNotif(props.notification.type);
    });

    function close(): void {
      notificationStore.CloseNotificationBanner(props.notification.id);
    }

    async function redirectUser(): Promise<void> {
      const notification = props.notification;
      const notificationPayload = props.notification.payload;

      const { path, query } = redirectFromNotification(
        notification,
        notificationPayload
      );

      await notificationStore.registerLastClickedNotification(notification);
      if (path !== "") {
        if (isOnCurrentPage(path, router.currentRoute.value.path)) {
          router.go(0);
        } else {
          await router.push({
            path,
            query: query as any,
          });
        }
      }
    }

    return {
      // Computed
      isUserMessageMentionNotification,
      isUserJoiningOperationNotification,
      isOperationDocumentUploadedNotification,
      isDemandeProjectUploadedNotification,
      isDemandeProjectValidatedNotification,
      isDemandeSignMethodChosenNotification,
      isDemandeSignedNotification,
      isDemandeOutdatedNotification,
      isDemandeSharedWithBanqueNotification,
      isLettreAccordValidatedNotification,
      isLettreAccordProjectUploadedNotification,
      isLettreAccordSignReadyNotification,
      isLettreAccordSignedNotification,
      isSureteCreatedNotification,
      isSureteProjectUploadedNotification,
      isPoolJoinDemandeNotification,
      isSureteProjectValidatedNotification,
      isSureteSignReadyNotification,
      isSureteSignedNotification,
      isActeSecondaireCreatedNotification,
      isOperationMessageSummaryExternalNotification,
      isOperationMessageSummaryChefDeFileNotification,
      isOperationMessageSummaryInternalNotification,
      isOperationMessageSummaryPoolNotification,
      isMainleveeRequestNotification,
      isMainleveeDocumentUploadedNotification,
      isMainleveeRefusedNotification,
      isMainleveeValidatedNotification,
      isCmpNewDocumentNotification,
      isCmpSureteSignedNotification,
      isAccordCommercialProjetAjouteNotification,
      isAccordCommercialProjetValideNotification,
      isAccordCommercialProjetDeclinedNotification,
      isAccordCommercialProjetPretASigneNotification,
      isAccordCommercialProjetSigneNotification,
      isSuiviTravauxEtapeDeclarationNotification,
      isSuiviCommercialisationNewImportNotification,
      isSuiviCommercialisationEditionTrancheNotification,
      isOperationArchivedByPartnerNotification,
      isEsgNouvelleVersionNotification,
      isEsgBanqueAskPromoteurForFormNotification,
      isOperationArchivageAutoNotification,
      isSuiviBudgetPromoteurImportNotification,

      // Methods
      close,
      redirectUser,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.notification-banner-container {
  display: flex;
  flex-direction: column;
  transition: all 500s;
  animation: banner 5s forwards;

  .banner-item {
    display: flex;
    flex-direction: column;
    margin: 8px 0;

    .arrow-up {
      width: 16px;
      height: 10px;
      background-color: #ffffff;
      margin-left: auto;
      margin-right: 20px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.16);
      clip-path: polygon(50% 0, 0 100%, 100% 100%);
    }
  }
}

@keyframes banner {
  0% {
    opacity: 0.2;
  }
  5% {
    opacity: 0.8;
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>
