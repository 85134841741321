<template>
  <form class="user-form">
    <div class="user-name-input-group">
      <div class="user-input-group small-input">
        <label class="semi-bold">Prénom</label>
        <input
          :value="user.firstName"
          :class="userFirstNameEmptyClass"
          name="firstnameUser"
          @change="(e) => changeProperty('firstName', e)"
        />
        <div
          v-if="isFirstNameEmpty && isSubmitted"
          class="name-error-message error-container"
          data-test="first-name-error"
        >
          Vous n'avez pas renseigné de prénom
        </div>
      </div>

      <div class="user-input-group small-input">
        <label class="semi-bold">Nom</label>
        <input
          :value="user.lastName"
          :class="userLastNameEmptyClass"
          name="nomUser"
          @change="(e) => changeProperty('lastName', e)"
        />
        <div
          v-if="isLastNameEmpty && isSubmitted"
          class="error-container name-error-message"
          data-test="last-name-error"
        >
          Vous n'avez pas renseigné de nom
        </div>
      </div>
    </div>
    <div v-if="!isTiers" class="user-input-group">
      <label class="semi-bold"
        >Fonction<span v-if="!isHommeDeArt" class="label-optional">
          (optionnelle)</span
        ></label
      >
      <input
        :value="user.jobTitle"
        name="jobTitle"
        :class="userJobTitleEmptyClass"
        :required="isHommeDeArt"
        data-test="job-input"
        @change="(e) => changeProperty('jobTitle', e)"
      />
      <div
        v-if="isHommeDeArt && isJobTitleEmpty && isSubmitted"
        class="error-container name-error-message"
        data-test="last-name-error"
      >
        Vous n'avez pas renseigné votre fonction
      </div>
    </div>
    <div class="user-input-group small-input phone-input">
      <label class="semi-bold"
        >N° de téléphone <span class="label-optional"> (optionnel)</span></label
      >
      <input
        :value="user.phoneNumber"
        :class="userPhoneNumberBadFormatClass"
        data-test="phone-input"
        name="phoneNumber"
        @change="(e) => changeProperty('phoneNumber', e)"
      />
      <div
        v-if="!isUserPhoneNumberValid && isSubmitted"
        class="name-error-message error-container"
        data-test="phone-error"
      >
        Veuillez verifier le format saisi
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { UserUpdateRequest } from "@domain/dto/userUpdateRequest";
import { NeomiEvent } from "@/domain/neomiEvent";
import { isPhoneNumberValid } from "@/utils/validationFormUtils";
import { computed, defineComponent } from "vue";
import { getSetupContext } from "@/utils/vueUtils";

export default defineComponent({
  props: {
    user: {
      type: Object as () => UserUpdateRequest,
      required: true,
    },
    isSubmitted: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const { keycloakStore } = getSetupContext();

    const isTiers = computed<boolean>(() => {
      return keycloakStore.isTiers;
    });

    const isHommeDeArt = computed<boolean>(() => {
      return keycloakStore.isHommeDeArt;
    });

    const isFirstNameEmpty = computed((): boolean => {
      return props.user.firstName === "";
    });

    const userFirstNameEmptyClass = computed((): string => {
      return isFirstNameEmpty.value && props.isSubmitted ? "input-error" : "";
    });

    const isLastNameEmpty = computed((): boolean => {
      return props.user.lastName === "";
    });

    const userLastNameEmptyClass = computed((): string => {
      return isLastNameEmpty.value && props.isSubmitted ? "input-error" : "";
    });

    const userPhoneNumberBadFormatClass = computed((): string => {
      return !isUserPhoneNumberValid.value && props.isSubmitted
        ? "input-error"
        : "";
    });

    const isJobTitleEmpty = computed((): string => {
      return props.user.jobTitle === "" && props.isSubmitted
        ? "input-error"
        : "";
    });

    const userJobTitleEmptyClass = computed((): string => {
      return isJobTitleEmpty.value && props.isSubmitted ? "input-error" : "";
    });

    const isUserPhoneNumberValid = computed((): boolean => {
      if (!props.user.phoneNumber) {
        return true;
      } else {
        return props.isSubmitted && isPhoneNumberValid(props.user.phoneNumber);
      }
    });

    function changeProperty(name: string, event: Event): void {
      const changed: { [key: string]: unknown } = {};
      changed[name] = (event.target as HTMLInputElement).value;
      context.emit(NeomiEvent.UPDATE, changed);
    }

    return {
      // Computed
      isFirstNameEmpty,
      userFirstNameEmptyClass,
      isLastNameEmpty,
      userLastNameEmptyClass,
      userPhoneNumberBadFormatClass,
      isUserPhoneNumberValid,
      isJobTitleEmpty,
      userJobTitleEmptyClass,
      isTiers,
      isHommeDeArt,
      // Method
      changeProperty,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.user-update-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-error {
  border: 1px solid $error-1;
  border-radius: 4px;
  margin-bottom: 0;
}

.error-container {
  height: 18px;
  color: $error-1;
}

.header {
  margin: 76px auto 64px;
  text-align: center;
}

.user-input-group {
  display: flex;
  flex-direction: column;

  label {
    margin: 8px 0;
  }

  .label-optional {
    font-style: italic;
    font-weight: normal;
  }
}

.phone-input {
  min-width: 200px;
  margin-bottom: 8px;
  margin-top: 14px;
}

.small-input {
  width: 49%;
}

.user-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-name-input-group {
  display: flex;
  justify-content: space-between;
}

.name-error-message {
  margin-bottom: 30px;
}
</style>
