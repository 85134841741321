<template>
  <div class="footer">
    <div class="main-message mini">
      © COPYRIGHT {{ currentYear }} - Tous Droits Réservés | Neomi Version
      {{ ciVersion }} |
      <span class="rgpd" @click="goRGPD"
        >Politique de confidentialité des informations personnelles</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import config from "@/config";
import { currentYear as currentYearUtil } from "@/utils/dateUtils";
import { useRouter } from "vue-router";

import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const router = useRouter();

    const ciVersion = ref(config.VERSION);

    function goRGPD(): void {
      router.push("/rgpd");
    }

    const currentYear = computed((): number => {
      return currentYearUtil();
    });

    return {
      // Data
      ciVersion,

      // Computed
      currentYear,

      // Method
      goRGPD,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  .main-message {
    text-align: center;

    .rgpd {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
