export function normalizeString(string: string): string {
  return string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function arrayToString(array: string[]): string {
  return array.join(", ");
}

export function isIncluded(word: string, locationList: string[]): boolean {
  const lowerCaseWord = normalizeString(word);
  return locationList.some((item) =>
    normalizeString(item).includes(lowerCaseWord)
  );
}
