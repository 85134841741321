<template>
  <div
    v-for="maintenanceMessage of maintenanceMessageList"
    :key="maintenanceMessage.id"
    class="maintenance-message"
    :class="maintenanceMessage.level"
  >
    {{ maintenanceMessage.text }}
  </div>
</template>

<script lang="ts" setup>
import userApi from "@/api/userApi";
import { MaintenanceMessageResponse } from "@domain/dto/maintenanceMessageResponse";
import { onMounted, ref } from "vue";

const maintenanceMessageList = ref<MaintenanceMessageResponse[]>([]);

onMounted(async () => {
  const maintenanceMessageListResponse =
    await userApi.fetchMaintenanceMessageList();

  maintenanceMessageList.value = maintenanceMessageListResponse.data;
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.maintenance-message {
  width: 100%;
  text-align: left;
  padding: 10px 24px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  &.INFO {
    background: rgba($success-2, 0.05);
    color: $success-2;
    border-color: $success-2;
  }

  &.WARNING {
    background: rgba($warn-2, 0.05);
    color: $warn-2;
    border-color: $warn-2;
  }

  &.ERROR {
    background: rgba(#953d09, 0.05);
    color: #953d09;
    border-color: #953d09;
  }

  &.CRITICAL {
    background: rgba($error-2, 0.05);
    color: $error-2;
    border-color: $error-2;
  }
}
</style>
