<template>
  <div class="modal-mask" @scroll="scroll">
    <ConditionGeneraleUtilisationModalText class="cgu-container" />
    <div class="cgu-footer">
      <div class="cgu-validation">
        <NCheckbox24
          id="1"
          :value="validated"
          data-test="checkbox-cgu"
          @check="tickCheckBox"
        />
        <div class="disclaimer" @click=tickCheckBox>
          En cochant cette case, je confirme avoir pris connaissance des
          conditions générales d'utilisation et de confidentialité de la
          plateforme NEOMI.
        </div>
        <button
          :disabled="!canContinue"
          class="primary validation-button"
          @click="validateConditionGeneraleUtilisation"
        >
          Continuer vers NEOMI
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NCheckbox24 from "@/components/shared/NCheckbox24.vue";
import ConditionGeneraleUtilisationModalText from "@/components/conditionGeneraleUtilisation/ConditionGeneraleUtilisationModalText.vue";

import { computed, ref, defineComponent } from "vue";
import useKeycloakStore from "@/store/keycloakModule.pinia";
export default defineComponent({
  name: "ConditionGeneraleUtilisationModal",
  components: { NCheckbox24, ConditionGeneraleUtilisationModalText },
  setup() {
    const keycloakStore = useKeycloakStore();

    const validated = ref<boolean>(false);
    const endOfScroll = ref<boolean>(false);

    const canContinue = computed<boolean>(() => {
      return validated.value && endOfScroll.value;
    });

    function tickCheckBox(): void {
      validated.value = !validated.value;
    }

    function scroll(event: Event): void {
      const target = <HTMLElement>event.target;

      const height = target.offsetHeight + target.scrollTop;
      const heightDiff = Math.abs(height - target.scrollHeight);
      endOfScroll.value = heightDiff < 5;
    }

    function validateConditionGeneraleUtilisation(): void {
      keycloakStore.updateUserHasReadConditionGeneraleUtilisation();
    }
    return {
      validated,
      canContinue,
      tickCheckBox,
      validateConditionGeneraleUtilisation,
      scroll,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.modal-mask {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  overflow: auto;

  .cgu-container {
    display: flex;
    background-color: $white;

    margin: 80px auto;
    padding: 0 10%;
    width: 95%;
    @media (min-width: #{$screen-lg-min}) {
      width: 70%;
    }
  }

  .cgu-footer {
    display: flex;
    position: sticky;
    background-color: $white;
    bottom: 0;
    left: 0;
    min-height: 96px;
    width: 100%;
    box-shadow: -2px 0 16px 0 rgba(0, 0, 0, 0.16);
    justify-content: center;

    .cgu-validation {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10%;

      .disclaimer {
        margin: 0 32px;
        text-align: left;
      }

      .validation-button {
        margin: 0 16px;
      }
    }
  }
}
</style>
