<template>
  <div class="sticker">
    <span>{{ countValue }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "StickerComponent",
  props: {
    countValue: {
      type: String,
      required: true,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.sticker {
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  color: $white;
  background-color: $primary-main;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  min-width: 22px;
  min-height: 22px;
  padding: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
