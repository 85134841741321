<template>
  <router-link class="centre-aide-container" to="/help" target="_blank">
    <div class="wheel-container">
      <img alt="centre d'aide" src="@/assets/cogwheel.svg" />
    </div>
    <div class="other-tab-container">
      <img alt="ouvrir dans un autre onglet" src="@/assets/other_tab.svg" />
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  setup() {},
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.centre-aide-container {
  position: relative;

  .other-tab-container {
    visibility: hidden;
    width: 0;
  }

  &:hover {
    .other-tab-container {
      visibility: visible;
      position: absolute;
      left: 22px;
      top: 0;
    }
  }
}
</style>
