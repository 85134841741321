<template>
  <div class="modal-mask">
    <div class="cancel-button">
      <img
        alt="close modal"
        data-test="cancel-button"
        src="@/assets/cross.svg"
        @click="close"
      />
    </div>
    <div class="pdf-viewer-container">
      <embed
        v-if="previewDocument"
        :src="previewDocument"
        height="100%"
        style="border: none"
        title="file-display"
        type="application/pdf"
        width="100%"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { getSetupContext } from "@/utils/vueUtils";

import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "PreviewDocumentModal",
  setup() {
    const { documentStore } = getSetupContext();

    const previewDocument = computed<string>(() => {
      return documentStore.getPreviewDocument;
    });

    function close(): void {
      documentStore.SetIsShowPreviewDocument(false);
      documentStore.SetPreviewDocument("");
    }

    return { previewDocument, close };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.modal-mask {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  overflow: auto;

  .pdf-viewer-container {
    display: flex;
    background-color: $white;
    width: 95%;
    margin: 80px auto;
    overflow-y: auto;
    height: calc(100vh - 60px);
    border-radius: 16px;
    border: 2px solid $primary-main;

    .pdf-viewer {
      width: 100%;
    }
  }
}

.cancel-button {
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 2;

  img {
    height: 48px;
    width: 48px;
    cursor: pointer;
    transition: all linear 0.1s;

    &:hover {
      height: 56px;
      width: 56px;
    }
  }
}
</style>
