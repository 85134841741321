import { apiRequest, apiRequestThrottle, ApiResponse } from "@/apiRequest";
import { AccordCommercialDocument } from "@domain/dto/accordCommercialDocument";
import { PoolAccordCommercialCreate } from "@domain/dto/poolAccordCommercialCreate";
import { PoolAccordCommercial } from "@/domain/poolAccordCommercial";
import { PoolAccordCommercialHeavy } from "@domain/dto/poolAccordCommercialHeavy";
import { PoolAccordCommercialBulkCreate } from "@domain/dto/poolAccordCommercialBulkCreate";
import { Demande } from "@/domain/demande";
import { WorkflowRollbackType } from "@/domain/enum/workflowRollbackType";

export default {
  fetchPoolAccordCommercialisationListForOperation(
    idOperation: string
  ): Promise<ApiResponse<PoolAccordCommercialHeavy[]>> {
    return apiRequest.get(`/operations/${idOperation}/accords-commerciaux`);
  },

  create(
    idOperation: string,
    poolAccordCommercialCreate: PoolAccordCommercialCreate
  ): Promise<ApiResponse<PoolAccordCommercial>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/accords-commerciaux`,
      poolAccordCommercialCreate,
      {
        validateStatus: (status) => {
          return status === 201 || status === 400 || status === 409;
        },
      }
    );
  },

  bulkCreate(
    idOperation: string,
    poolAccordCommercialBulkCreate: PoolAccordCommercialBulkCreate
  ): Promise<ApiResponse<PoolAccordCommercialHeavy[]>> {
    return apiRequestThrottle.post(
      `/operations/${idOperation}/accords-commerciaux/bulk`,
      poolAccordCommercialBulkCreate,
      {
        validateStatus: (status) => {
          return status === 201 || status === 400 || status === 409;
        },
      }
    );
  },

  bulkUploadProject(
    idOperation: string,
    idPoolAccordCommercialList: string[],
    file: File
  ): Promise<ApiResponse<void>> {
    const formData = new FormData();
    formData.append("project", file);

    return apiRequestThrottle.put(
      `/operations/${idOperation}/accords-commerciaux/bulk`,
      formData,
      {
        validateStatus: (status) => {
          return status === 201 || status === 400;
        },
        params: {
          idPoolAccordCommercialList,
        },
      }
    );
  },

  startSignatureCeremonie(
    idOperation: string,
    idPoolAccordCommercial: string
  ): Promise<ApiResponse<string>> {
    return apiRequest.post(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}/signature/start-ceremonie`
    );
  },

  fetchSignedActe(
    idOperation: string,
    idPoolAccordCommercial: string
  ): Promise<ApiResponse<AccordCommercialDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}/signed-acte`
    );
  },

  fetchCertificate(
    idOperation: string,
    idPoolAccordCommercial: string
  ): Promise<ApiResponse<AccordCommercialDocument>> {
    return apiRequest.get(
      `/operations/${idOperation}/accords-commerciaux/${idPoolAccordCommercial}/certificate`
    );
  },

  rollbackWorkflow(
    idOperation: string,
    idAccordCommercial: string,
    rollbackType: WorkflowRollbackType
  ): Promise<ApiResponse<string>> {
    return apiRequestThrottle.patch(
      `/operations/${idOperation}/accords-commerciaux/${idAccordCommercial}/workflow/rollback`,
      { rollbackType }
    );
  },

  remindSignatureToRecipient(
    idOperation: string,
    idAccordCommercial: string,
    idRecipient: number
  ): Promise<ApiResponse<Demande>> {
    return apiRequestThrottle.put(
      `/operations/${idOperation}/accords-commerciaux/${idAccordCommercial}/signature/remind/${idRecipient}`,
      {},
      {
        validateStatus: (status) => [201, 400, 403, 409].includes(status),
      }
    );
  },

  deleteAccordCommercial(idOperation: string, idAccordCommercial: string) {
    return apiRequestThrottle.delete(
      `/operations/${idOperation}/accords-commerciaux/${idAccordCommercial}/delete`
    );
  },
};
