export enum VariationType {
  GOOD = "GOOD",
  BAD = "BAD",
  NONE = "NONE",
}

export interface SuiviVariation {
  variationType: VariationType;
  variationPourcentage: VariationPourcentage;
  ratio: number;
  tooltipContent?: string;
}

export type VariationPourcentage = string | "infinity";
