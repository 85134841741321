<template>
  <div>
    <div class="navbar">
      <div class="main-tabs">
        <div class="logo">
          <router-link to="/">
            <img alt="logo neomi" src="@/assets/neomi-logo-centered.svg" />
          </router-link>
        </div>
        <div class="navbar-buttons-container">
          <router-link to="/accueil">
            <div
              id="navbar-dashboard-tab"
              :class="dashboardSelectedClass"
              class="navbar-tab"
              data-test="dashboard"
            >
              Tableau de bord
            </div>
          </router-link>
          <router-link v-if="canSeeSocieteSupportLink" to="/societes-support">
            <div
              id="navbar-societe-support-tab"
              :class="societesSupportSelectedClass"
              class="navbar-tab"
              data-test="societe"
            >
              Sociétés support
            </div>
          </router-link>
          <router-link v-if="canSeeAnnuaireLink" to="/annuaire">
            <div
              :class="annuaireSelectedClass"
              class="navbar-tab"
              data-test="annuaire"
            >
              Annuaire
            </div>
          </router-link>
          <router-link v-if="canSeeCommercialisationLink" to="/suivis">
            <div
              :class="commercialisationSelectedClass"
              class="navbar-tab"
              data-test="commercialisation"
            >
              Gestion des suivis
            </div>
          </router-link>
          <router-link v-if="canSeeReportingLink" to="/reporting">
            <div
              :class="reportingSelectedClass"
              class="navbar-tab"
              data-test="reporting"
            >
              Reporting
            </div>
          </router-link>
        </div>
      </div>
      <div class="transversal-tools">
        <CentreAideButton
          v-if="canSeeInfo"
          data-test="centre-aide"
          class="transversal-element"
        />
        <NotificationButton
          class="transversal-element"
          data-test="notification"
        />
        <div
          class="hand transversal-element"
          data-test="profile"
          @click="userProfile"
        >
          <UserProfileImage
            :full-name-initials="fullNameInitials"
            :id-profile-image="getIdProfileImage"
            :is-super-user="isSuperUser"
            :profile-picture-size="size"
          />
        </div>
      </div>
    </div>
    <NotificationBannerList />
    <SidePanel />
    <NMask />
  </div>
</template>

<script lang="ts">
import UserProfileImage from "@/components/profile/UserProfileImage.vue";
import SidePanel from "@/components/shared/SidePanel.vue";
import NotificationButton from "@/components/notification/NotificationButton.vue";
import NotificationBannerList from "@/components/notification/NotificationBannerList.vue";
import NMask from "@/components/shared/NMask.vue";
import { RouteName } from "@/routeName";
import CentreAideButton from "@/components/centreAide/CentreAideButton.vue";
import { getSetupContext } from "@/utils/vueUtils";
import { computed, defineComponent } from "vue";

import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    CentreAideButton,
    UserProfileImage,
    NotificationButton,
    NotificationBannerList,
    SidePanel,
    NMask,
  },
  setup() {
    const {
      keycloakStore,
      routeStore,
      isBanque,
      isPromoteur,
      isPool,
      isSuperUser,
    } = getSetupContext();
    const router = useRouter();

    const operationViews: RouteName[] = [
      RouteName.OPERATION_DEMANDES,
      RouteName.OPERATION_DEMANDE,
      RouteName.OPERATION_DOCUMENTS,
      RouteName.OPERATION_EQUIPE,
    ];
    const societesSupportViews: RouteName[] = [
      RouteName.SOCIETE_SUPPORT_LIST,
      RouteName.SOCIETE_SUPPORT_BANQUES,
      RouteName.SOCIETE_SUPPORT_OPERATIONS,
      RouteName.SOCIETE_SUPPORT_DOCUMENTS,
      RouteName.SOCIETE_SUPPORT_SURETES,
      RouteName.SOCIETE_SUPPORT_SURETE,
    ];
    const commercialisationViews: RouteName[] = [
      RouteName.COMMERCIALISATION_SUIVI,
      RouteName.COMMERCIALISATION_SUIVI_BANQUE,
    ];
    const size = "small-size-user-profile";

    const dashboardSelectedClass = computed((): string => {
      return routeStore.getActiveRouteName === RouteName.DASHBOARD ||
        routeStore.getActiveRouteName === RouteName.DASHBOARD_HOMME_DE_ART
        ? "selected-tab"
        : "";
    });

    const operationsSelectedClass = computed((): string => {
      const activeRoute = routeStore.getActiveRouteName as RouteName;
      return operationViews.includes(activeRoute) ? "selected-tab" : "";
    });

    const societesSupportSelectedClass = computed((): string => {
      const activeRoute = routeStore.getActiveRouteName as RouteName;
      return societesSupportViews.includes(activeRoute) ? "selected-tab" : "";
    });

    const commercialisationSelectedClass = computed((): string => {
      const activeRoute = routeStore.getActiveRouteName as RouteName;
      return commercialisationViews.includes(activeRoute) ? "selected-tab" : "";
    });

    const annuaireSelectedClass = computed((): string => {
      return routeStore.getActiveRouteName === RouteName.ANNUAIRE_HOME
        ? "selected-tab"
        : "";
    });

    const reportingSelectedClass = computed((): string => {
      return routeStore.getActiveRouteName === RouteName.REPORTING
        ? "selected-tab"
        : "";
    });

    const fullNameInitials = computed(
      (): string => keycloakStore.getInitialsOfName
    );

    const getIdProfileImage = computed((): string => {
      return keycloakStore.idProfileImage;
    });

    const canSeeSocieteSupportLink = computed((): boolean => {
      return isPromoteur.value || isBanque.value || isPool.value;
    });

    const canSeeAnnuaireLink = computed((): boolean => {
      return isPromoteur.value || isBanque.value || isPool.value;
    });

    const canSeeCommercialisationLink = computed((): boolean => {
      return isPromoteur.value || isBanque.value || isPool.value;
    });

    const canSeeReportingLink = computed((): boolean => {
      return isPromoteur.value || isBanque.value;
    });

    const canSeeInfo = computed((): boolean => {
      return isPromoteur.value || isBanque.value;
    });

    function userProfile(): void {
      router.push({
        path: `/profile`,
      });
    }

    return {
      // Property
      size,

      // Computed
      isBanque,
      isPromoteur,
      dashboardSelectedClass,
      operationsSelectedClass,
      societesSupportSelectedClass,
      commercialisationSelectedClass,
      annuaireSelectedClass,
      reportingSelectedClass,
      fullNameInitials,
      isSuperUser,
      getIdProfileImage,
      canSeeSocieteSupportLink,
      canSeeAnnuaireLink,
      canSeeCommercialisationLink,
      canSeeReportingLink,
      canSeeInfo,

      // Method
      userProfile,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;
@use "@/sass/media.scss" as *;

.navbar {
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  padding: 0 24px;
  border: none;

  .main-tabs {
    display: flex;
    align-items: center;
    height: 100%;

    .logo {
      display: none;

      @include md {
        display: contents;
        margin-right: 48px;
      }

      img {
        height: 64px;
        max-width: 12rem;
        margin-top: 10px;
        margin-right: 24px;
      }

      .router-link-active {
        display: flex;
        width: 144px;
      }
    }

    .navbar-buttons-container {
      display: flex;
      justify-content: flex-start;
      height: 100%;

      div {
        display: flex;
        align-items: center;
      }

      a {
        display: flex;
        text-decoration: none;
        margin: 0 4px;
        @include sm {
          margin: 0 8px;
        }
        @include md {
          margin: 0 12px;
        }

        .navbar-tab {
          color: $font-1;
          text-decoration: none;
          display: flex;
          font-weight: 600;
          align-items: center;
        }

        .navbar-tab:hover {
          color: $primary-hover;
          text-decoration: none;
        }

        .selected-tab {
          color: $primary-main;
        }
      }
    }
  }

  .transversal-tools {
    display: flex;
    align-items: center;

    .transversal-element {
      margin-left: 16px;
      display: flex;
      justify-content: center;
    }
  }

  .hand {
    cursor: pointer;
  }
}
</style>
