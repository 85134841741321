<template>
  <div :class="{ active: showPanel }" class="modal-mask"></div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { getSetupContext } from "@/utils/vueUtils";
export default defineComponent({
  setup() {
    const { panelStore } = getSetupContext();

    const showPanel = computed((): boolean => {
      return panelStore.showPanel;
    });

    return {
      showPanel,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.modal-mask {
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
  width: 0;

  &.active {
    opacity: 1;
    transition: opacity 0.5s;
    z-index: 499;
    width: 100%;
  }
}
</style>
