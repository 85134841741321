export enum SuiviBudgetPoste {
  TERRAIN_NUMERAIRE = "TERRAIN_NUMERAIRE",
  TERRAIN_DATION = "TERRAIN_DATION",
  FRAIS_ACQUISITION = "FRAIS_ACQUISITION",
  INDEMNITES = "INDEMNITES",
  INTERMEDIAIRE_FONCIER = "INTERMEDIAIRE_FONCIER",
  TAXES_PARTICIPATIONS = "TAXES_PARTICIPATIONS",
  AUTRES_CHARGES_FONCIER = "AUTRES_CHARGES_FONCIER",
  DEMOLITION_DESAMIANTAGE_DEPOLLUTION = "DEMOLITION_DESAMIANTAGE_DEPOLLUTION",
  BRANCHEMENTS_TRAVAUX_PREPARATOIRES = "BRANCHEMENTS_TRAVAUX_PREPARATOIRES",
  TRAVAUX_VRD_ESPACES_VERTS = "TRAVAUX_VRD_ESPACES_VERTS",
  ALEAS_TRAVAUX_SUPPLEMENTAIRES_VRD_ESPACES_VERTS = "ALEAS_TRAVAUX_SUPPLEMENTAIRES_VRD_ESPACES_VERTS",
  TRAVAUX_BATIMENT = "TRAVAUX_BATIMENT",
  ALEAS_TRAVAUX_SUPPLEMENTAIRES = "ALEAS_TRAVAUX_SUPPLEMENTAIRES",
  TRAVAUX_MODIFICATIFS = "TRAVAUX_MODIFICATIFS",
  TRAVAUX_ANNEXES = "TRAVAUX_ANNEXES",
  HONORAIRES_TECHNIQUES_DIVERS = "HONORAIRES_TECHNIQUES_DIVERS",
  GEOMETRE = "GEOMETRE",
  ARCHITECTE_PRESTATION_CONCEPTION = "ARCHITECTE_PRESTATION_CONCEPTION",
  MAITRISE_OEUVRE_PRESTATIONS_EXECUTION_EXTERNE = "MAITRISE_OEUVRE_PRESTATIONS_EXECUTION_EXTERNE",
  BUREAUX_ETUDES = "BUREAUX_ETUDES",
  CONTROLE_TECHNIQUE_SECURITE = "CONTROLE_TECHNIQUE_SECURITE",
  ASSURANCES = "ASSURANCES",
  MAITRISE_OEUVRE_INTERNE = "MAITRISE_OEUVRE_INTERNE",
  CHARGES_DIVERSES = "CHARGES_DIVERSES",
  CHARGES_ADMINISTRATION = "CHARGES_ADMINISTRATION",
  SAV_CONTENTIEUX = "SAV_CONTENTIEUX",
  PUBLICITE = "PUBLICITE",
  AIDES_VENTE = "AIDES_VENTE",
  HONORAIRES_EXTERNES_COMMERCIALISATION = "HONORAIRES_EXTERNES_COMMERCIALISATION",
  HONORAIRES_INTERNES_COMMERCIALISATION = "HONORAIRES_INTERNES_COMMERCIALISATION",
  HONORAIRES_GESTION_EXTERNE = "HONORAIRES_GESTION_EXTERNE",
  HONORAIRES_GESTION_INTERNE = "HONORAIRES_GESTION_INTERNE",
  GARANTIE_FINANCIERE_ACHEVEMENT = "GARANTIE_FINANCIERE_ACHEVEMENT",
  CAUTIONS_GARANTIES = "CAUTIONS_GARANTIES",
  FRAIS_FINANCIERS_VENDEUR_ACQUEREUR = "FRAIS_FINANCIERS_VENDEUR_ACQUEREUR",
  FRAIS_TENUE_COMPTE = "FRAIS_TENUE_COMPTE",
  FRAIS_FINANCIERS_EXTERNES = "FRAIS_FINANCIERS_EXTERNES",
  FRAIS_FINANCIERS_INTERNES = "FRAIS_FINANCIERS_INTERNES",
  VENTE_DETAIL = "VENTE_DETAIL",
  VENTE_BLOC = "VENTE_BLOC",
  DATION = "DATION",
  PERSONNALISATION = "PERSONNALISATION",
  REFACTURATIONS = "REFACTURATIONS",
  IGNORE = "IGNORE",
  EMPTY = "EMPTY",
}

type SuiviBudgetPosteWithoutLigneType =
  | SuiviBudgetPoste.IGNORE
  | SuiviBudgetPoste.EMPTY;

export type SuiviBudgetPosteHavingLigneType = Exclude<
  SuiviBudgetPoste,
  SuiviBudgetPosteWithoutLigneType
>;

export function hasSuiviBudgetPosteLigneType(
  poste: SuiviBudgetPoste
): poste is SuiviBudgetPosteHavingLigneType {
  return ![SuiviBudgetPoste.IGNORE, SuiviBudgetPoste.EMPTY].includes(poste);
}
