import { CommercialisationImportConfiguration } from "@/domain/commercialisationImportConfiguration";
import { EtapeCommercialisationImport } from "@/domain/enum/etapeCommercialisationImport";
import { EtapeCommercialisationImportNumber } from "@/utils/model/etapeCommercialisationImportNumber";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import commercialisationApi from "@/api/commercialisationApi";
import {
  arrangeCommercialisationOperationDemande,
  arrangeCommercialisationOperationTranche,
  checkDataValid,
  convertImportMatrixRowToCreationRequest,
  convertImportMatrixToPreviewList,
  excludeBeforeFirstLineFromRawMatrix,
  excludeFirstLineFromRawMatrix,
  extractTrancheDuplicates,
  getSanitizedMatrix,
  isCommercialisationImportReady,
} from "@/utils/suiviCommercialisationUtils";
import { CommercialisationPreviewRow } from "@/domain/commercialisationPreviewRow";
import { ChoixIdentifiantCommercialisationImport } from "@domain/enum/choixIdentifiantCommercialisationImport";
import { CommercialisationOperationDemande } from "@/domain/commercialisationOperationDemande";
import { SuiviCommercialisationTableau } from "@domain/dto/suiviCommercialisationTableau";
import { UserLite } from "@/domain/userLite";
import { SuiviCommercialisationAllErrors } from "@domain/dto/suiviCommercialisationAllErrors";
import { SuiviCommercialisationPromoteur } from "@domain/dto/suiviCommercialisationPromoteur";
import { ChoixUniteEuroCommercialisationImport } from "@domain/enum/choixUniteEuroCommercialisationImport";
import { TrancheDemandeCreationRequest } from "@domain/dto/trancheDemandeCreationRequest";
import {
  PotentialDemande,
  TrancheOperation,
  UnboundTranche,
  UnboundTranchesGroupedByOperation,
} from "@domain/dto/unboundTranchesGroupedByOperation";
import { TrancheDemandeUnboundAmount } from "@/domain/trancheDemandeUnboundAmount";
import { SuiviCommercialisationTrancheAmounts } from "@/domain/dto/suiviCommercialisationTrancheAmounts";
import { SuiviReferenceTrancheDuplicateError } from "@domain/dto/suiviReferenceTrancheDuplicateError";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { Buffer } from "buffer";
import { SuiviCommercialisationUpdateRequest } from "@domain/dto/suiviCommercialisationUpdateRequest";
import { ModuleType } from "@/store/moduleType.pinia";
import { defineStore } from "pinia";
import { convertEuroToCentime } from "@/utils/numberUtils";
import { useUserProfileStore } from "./userProfileModule.pinia";
import { SuiviCommercialisationTableauHeavy } from "@domain/dto/suiviCommercialisationTableauHeavy";
import { CommercialisationOperationTranche } from "@/domain/commercialisationOperationTranche";
import { SuiviComTableType } from "@/domain/enum/suiviComTableType";
import { SuiviComViewType } from "@/domain/enum/suiviComViewType";

type SuiviCommercialisationStateType = {
  rawDataMatrix: string[][];
  isConfigurationImported: boolean;
  isUserEditingSuiviCommercialisation: boolean;
  commercialisationImportConfiguration: CommercialisationImportConfiguration;
  previewMode: boolean;
  suiviCommercialisationTableau: SuiviCommercialisationTableauHeavy;
  suiviCommercialisationAllErrors: SuiviCommercialisationAllErrors;
  suiviCommercialisationPromoteurList: SuiviCommercialisationPromoteur[];
  suiviCommercialisationReferenceTrancheDuplicateErrors: SuiviReferenceTrancheDuplicateError[];
  suiviCommercialisationUpdateRequestList: SuiviCommercialisationUpdateRequest[];
  suiviCommercialisationUnboundTrancheErrors: UnboundTranchesGroupedByOperation[];
  fixTrancheAmount: TrancheDemandeUnboundAmount[];
  suiviCommercialisationFile?: File;
  suiviComTableType: SuiviComTableType;
  suiviComViewType: SuiviComViewType;
  extendedViewGfaItemList: string[];
  extendedViewTrancheItemList: string[];
  extendedViewGfaItemCount: number;
  extendedViewTrancheItemCount: number;
};

export const useSuiviCommercialisationStore = defineStore(
  ModuleType.SuiviCommercialisation,
  {
    state: (): SuiviCommercialisationStateType => ({
      rawDataMatrix: [],
      isConfigurationImported: false,
      isUserEditingSuiviCommercialisation: false,
      commercialisationImportConfiguration: {
        choixIdentifiant:
          ChoixIdentifiantCommercialisationImport.REFERENCE_PROMOTEUR,
        choixChiffreAffaire: ChoixUniteEuroCommercialisationImport.EURO,
      },
      previewMode: false,
      suiviCommercialisationTableau: {
        latestImportDate: new Date(),
        lastAuthor: { id: "", firstName: "", lastName: "" },
        suiviCommercialisationTableauLigneList: [],
        suiviCommercialisationTableauTrancheLigneList: [],
      },
      suiviCommercialisationAllErrors: {
        suiviCommercialisationActeErrors: [],
        suiviCommercialisationTrancheError: [],
      },
      suiviCommercialisationPromoteurList: [],
      suiviCommercialisationReferenceTrancheDuplicateErrors: [],
      suiviCommercialisationUpdateRequestList: [],
      suiviCommercialisationUnboundTrancheErrors: [],
      fixTrancheAmount: [],
      suiviComTableType: SuiviComTableType.GROUP_BY_TRANCHE,
      suiviComViewType: SuiviComViewType.SUIVI_COMMERCIALISATION,
      extendedViewGfaItemList: [],
      extendedViewTrancheItemList: [],
      extendedViewGfaItemCount: 0,
      extendedViewTrancheItemCount: 0,
    }),

    getters: {
      getSuiviCommercialisationPromoteurList(
        state
      ): SuiviCommercialisationPromoteur[] {
        return state.suiviCommercialisationPromoteurList;
      },

      getSuiviCommercialisationUpdateRequestList(
        state
      ): SuiviCommercialisationUpdateRequest[] {
        return state.suiviCommercialisationUpdateRequestList;
      },

      getTruncatedRawCommercialisationImportMatrix(state): string[][] {
        const rawMatrixWithFirstLine = excludeBeforeFirstLineFromRawMatrix(
          state.rawDataMatrix,
          state.commercialisationImportConfiguration
        );
        return rawMatrixWithFirstLine.slice(0, 50);
      },

      getRawDataMatrix(state): string[][] {
        return state.rawDataMatrix;
      },

      getCommercialisationFullImportMatrix(state): string[][] {
        return excludeFirstLineFromRawMatrix(
          state.rawDataMatrix,
          state.commercialisationImportConfiguration
        );
      },

      getCommercialisationPreviewData(state): CommercialisationPreviewRow[] {
        const configuration = state.commercialisationImportConfiguration;
        const matrix = excludeFirstLineFromRawMatrix(
          state.rawDataMatrix,
          configuration
        );
        return convertImportMatrixToPreviewList(matrix, configuration);
      },

      getSuiviCommercialisationAllErrors(
        state
      ): SuiviCommercialisationAllErrors {
        return state.suiviCommercialisationAllErrors;
      },

      getCommercialisationImportConfiguration(
        state
      ): CommercialisationImportConfiguration {
        return state.commercialisationImportConfiguration;
      },

      getPreviewMode(state): boolean {
        return state.previewMode;
      },

      getCommercialisationTableau(state): SuiviCommercialisationTableau {
        return state.suiviCommercialisationTableau;
      },

      getCommercialisationTableauLigneList(
        state
      ): CommercialisationOperationDemande[] {
        const currentUser = useUserProfileStore().getUserProfile;
        return arrangeCommercialisationOperationDemande(
          state.suiviCommercialisationTableau
            .suiviCommercialisationTableauLigneList,
          currentUser
        );
      },

      getCommercialisationTableauTrancheLigneList(
        state
      ): CommercialisationOperationTranche[] {
        const currentUser = useUserProfileStore().getUserProfile;
        return arrangeCommercialisationOperationTranche(
          state.suiviCommercialisationTableau
            .suiviCommercialisationTableauTrancheLigneList,
          currentUser
        );
      },

      getCommercialisationLatestImportDate(state): Date {
        return state.suiviCommercialisationTableau.latestImportDate;
      },

      getCommercialisationLatestImportAuthor(state): UserLite {
        return state.suiviCommercialisationTableau.lastAuthor;
      },

      getCommercialisationIsConfigurationImported(state): boolean {
        return state.isConfigurationImported;
      },
      getIsUserEditingSuiviCommercialisation(state): boolean {
        return state.isUserEditingSuiviCommercialisation;
      },

      getSuiviCommercialisationReferenceTrancheDuplicateErrors(
        state
      ): SuiviReferenceTrancheDuplicateError[] {
        return state.suiviCommercialisationReferenceTrancheDuplicateErrors;
      },

      getFixTrancheAmount(state): TrancheDemandeUnboundAmount[] {
        return state.fixTrancheAmount;
      },

      getSuiviCommercialisationUnboundTrancheErrors(
        state
      ): UnboundTranchesGroupedByOperation[] {
        return state.suiviCommercialisationUnboundTrancheErrors;
      },

      getSuiviComTableType(state): SuiviComTableType {
        return state.suiviComTableType;
      },

      getSuiviComViewType(state): SuiviComViewType {
        return state.suiviComViewType;
      },

      getExtendedViewGfaItemList(state): string[] {
        return state.extendedViewGfaItemList;
      },

      getExtendedViewGfaItemCount(state): number {
        return state.extendedViewGfaItemCount;
      },

      getExtendedViewTrancheItemList(state): string[] {
        return state.extendedViewTrancheItemList;
      },

      getExtendedViewTrancheItemCount(state): number {
        return state.extendedViewTrancheItemCount;
      },
    },

    actions: {
      SetFixTrancheAmount(
        fixTrancheAmount: TrancheDemandeUnboundAmount[]
      ): void {
        this.fixTrancheAmount = fixTrancheAmount;
      },

      SetFile(file: File): void {
        this.suiviCommercialisationFile = file;
      },

      SetSuiviCommercialisationPromoteurList(
        suiviCommercialisationPromoteurList: SuiviCommercialisationPromoteur[]
      ): void {
        this.suiviCommercialisationPromoteurList =
          suiviCommercialisationPromoteurList;
      },

      SetCommercialisationIsConfigurationImported(
        isConfigurationImported: boolean
      ) {
        this.isConfigurationImported = isConfigurationImported;
      },
      SetUserEditingSuiviCommercialisation(
        isUserEditingNumeroActeOrReferencePromoteur: boolean
      ) {
        this.isUserEditingSuiviCommercialisation =
          isUserEditingNumeroActeOrReferencePromoteur;
      },

      SetCommercialisationImportConfiguration(
        commercialisationImportConfiguration: CommercialisationImportConfiguration
      ) {
        this.commercialisationImportConfiguration =
          commercialisationImportConfiguration;
      },

      SetSuiviCommercialisationReferenceTrancheErrors(
        suiviCommercialisationReferenceTrancheDuplicateErrors: SuiviReferenceTrancheDuplicateError[]
      ) {
        this.suiviCommercialisationReferenceTrancheDuplicateErrors =
          suiviCommercialisationReferenceTrancheDuplicateErrors;
      },

      SetSuiviCommercialisationUnboundTrancheErrors(
        suiviCommercialisationUnboundTrancheErrors: UnboundTranchesGroupedByOperation[]
      ) {
        this.suiviCommercialisationUnboundTrancheErrors =
          suiviCommercialisationUnboundTrancheErrors;
      },

      SetSuiviComTableType(suiviComTableType: SuiviComTableType): void {
        if (suiviComTableType === SuiviComTableType.GROUP_BY_TRANCHE) {
          this.suiviComViewType = SuiviComViewType.SUIVI_COMMERCIALISATION;
        }

        this.suiviComTableType = suiviComTableType;
      },

      SetSuiviComViewType(suiviComViewType: SuiviComViewType): void {
        this.suiviComViewType = suiviComViewType;
      },

      InitializeCommercialisationImportMatrix(matrix: string[][]): void {
        this.rawDataMatrix = matrix;
      },

      ResetCommercialisationImportMatrix(): void {
        this.rawDataMatrix = [];
      },

      ResetSuiviCommercialisationUpdateRequestList(): void {
        this.suiviCommercialisationUpdateRequestList = [];
      },

      UpdateSuiviCommercialisationUpdateRequest(
        suiviCommercialisationUpdateRequest: SuiviCommercialisationUpdateRequest
      ): void {
        this.suiviCommercialisationUpdateRequestList = [
          ...this.suiviCommercialisationUpdateRequestList.filter(
            (request) => request.id !== suiviCommercialisationUpdateRequest.id
          ),
          { ...suiviCommercialisationUpdateRequest },
        ];
      },

      RemoveSuiviCommercialisationUpdateRequest(
        suiviCommercialisationUpdateRequestId: string
      ): void {
        this.suiviCommercialisationUpdateRequestList = [
          ...this.suiviCommercialisationUpdateRequestList.filter(
            (request) => request.id !== suiviCommercialisationUpdateRequestId
          ),
        ];
      },

      ResetCommercialisationImportConfiguration(): void {
        this.commercialisationImportConfiguration = {
          choixIdentifiant:
            ChoixIdentifiantCommercialisationImport.REFERENCE_PROMOTEUR,
          choixChiffreAffaire: ChoixUniteEuroCommercialisationImport.EURO,
        };
      },

      ResetSuiviCommercialisationReferenceTrancheErrors(): void {
        this.suiviCommercialisationReferenceTrancheDuplicateErrors = [];
      },

      ResetSuiviCommercialisationUnboundTrancheErrors(): void {
        this.suiviCommercialisationUnboundTrancheErrors = [];
      },

      SetCommercialisationImportEtape(
        state: EtapeCommercialisationImportNumber
      ): void {
        switch (state.etape) {
          case EtapeCommercialisationImport.CHOIX_PREMIERE_LIGNE:
            this.commercialisationImportConfiguration.premiereLigne =
              state.lineOrColumnNumber;
            break;
          case EtapeCommercialisationImport.CHOIX_COLONNE_IDENTIFIANT:
            if (
              this.commercialisationImportConfiguration.choixIdentifiant ===
              ChoixIdentifiantCommercialisationImport.NUMERO_ACTE
            ) {
              this.commercialisationImportConfiguration.numeroActe =
                state.lineOrColumnNumber;
              this.commercialisationImportConfiguration.referencePromoteur =
                undefined;
            } else if (
              this.commercialisationImportConfiguration.choixIdentifiant ===
              ChoixIdentifiantCommercialisationImport.REFERENCE_PROMOTEUR
            ) {
              this.commercialisationImportConfiguration.referencePromoteur =
                state.lineOrColumnNumber;
              this.commercialisationImportConfiguration.numeroActe = undefined;
            }
            break;
          case EtapeCommercialisationImport.CHOIX_COLONNE_TRANCHE:
            this.commercialisationImportConfiguration.tranche =
              state.lineOrColumnNumber;
            break;
          case EtapeCommercialisationImport.CHOIX_CA_TTC_TOTAL:
            this.commercialisationImportConfiguration.euroCentimeChiffreAffaireTtcTotal =
              state.lineOrColumnNumber;
            break;
          case EtapeCommercialisationImport.CHOIX_CA_TTC_ACTE:
            this.commercialisationImportConfiguration.euroCentimeChiffreAffaireTtcActe =
              state.lineOrColumnNumber;
            break;
          case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_TOTAL:
            this.commercialisationImportConfiguration.nombreLotTotal =
              state.lineOrColumnNumber;
            break;
          case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_ACTES:
            this.commercialisationImportConfiguration.nombreLotActe =
              state.lineOrColumnNumber;
            break;
          case EtapeCommercialisationImport.CHOIX_CA_TTC_RESERVE:
            this.commercialisationImportConfiguration.euroCentimeChiffreAffaireTtcReserve =
              state.lineOrColumnNumber;
            break;
          case EtapeCommercialisationImport.CHOIX_NOMBRE_LOTS_RESERVES:
            this.commercialisationImportConfiguration.nombreLotReserve =
              state.lineOrColumnNumber;
            break;
          case EtapeCommercialisationImport.CHOIX_ETAPE_NON_DEFINIE:
            break;
        }
        this.commercialisationImportConfiguration = {
          ...this.commercialisationImportConfiguration,
        };
      },

      SetCommercialisationImportChoixIdentifiant(
        choixIdentifiant: ChoixIdentifiantCommercialisationImport
      ): void {
        this.commercialisationImportConfiguration = {
          ...this.commercialisationImportConfiguration,
          choixIdentifiant,
        };
      },

      SetCommercialisationImportChoixUniteEuro(
        choixChiffreAffaire: ChoixUniteEuroCommercialisationImport
      ): void {
        this.commercialisationImportConfiguration = {
          ...this.commercialisationImportConfiguration,
          choixChiffreAffaire,
        };
      },

      SetPreviewMode(previewMode: boolean): void {
        this.previewMode = previewMode;
      },

      SetCommercialisationTableau(
        suiviCommercialisationTableau: SuiviCommercialisationTableauHeavy
      ): void {
        this.suiviCommercialisationTableau = suiviCommercialisationTableau;
      },

      SetCommercialisationActAllErreur(
        commercialisationAllErrors: SuiviCommercialisationAllErrors
      ) {
        this.suiviCommercialisationAllErrors = commercialisationAllErrors;
      },

      SetTrancheDemandeFixUnboundAmount(payload: {
        operation: TrancheOperation;
        idDemandeList: string[];
        tranche: UnboundTranche;
      }): void {
        this.SetFixTrancheAmount(
          payload.idDemandeList.map<TrancheDemandeUnboundAmount>(
            (idDemande) => {
              return {
                operation: payload.operation,
                tranche: payload.tranche,
                demande: payload.tranche.potentialDemandeList.find(
                  (demande) => demande.id === idDemande
                ) as PotentialDemande,
              };
            }
          )
        );
      },

      AddExtendedViewGfaItem(id: string, itemCount: number): void {
        this.extendedViewGfaItemList = [...this.extendedViewGfaItemList, id];
        this.extendedViewGfaItemCount += itemCount;
      },

      RemoveExtendedViewGfaItem(id: string, itemCount: number): void {
        this.extendedViewGfaItemList = this.extendedViewGfaItemList.filter(
          (idExtended) => idExtended !== id
        );
        this.extendedViewGfaItemCount += itemCount;
      },

      AddExtendedViewTrancheItem(id: string, itemCount: number): void {
        this.extendedViewTrancheItemList = [
          ...this.extendedViewTrancheItemList,
          id,
        ];
        this.extendedViewTrancheItemCount += itemCount;
      },

      RemoveExtendedViewTrancheItem(id: string, itemCount: number): void {
        this.extendedViewTrancheItemList =
          this.extendedViewTrancheItemList.filter(
            (idExtended) => idExtended !== id
          );
        this.extendedViewTrancheItemCount += itemCount;
      },

      async uploadCommercialisationJSONData(): Promise<void> {
        if (
          isCommercialisationImportReady(
            this.commercialisationImportConfiguration
          ) &&
          this.suiviCommercialisationFile
        ) {
          const matrix = this.getCommercialisationFullImportMatrix;
          const configuration = this.commercialisationImportConfiguration;
          const sanitizedMatrix = getSanitizedMatrix(matrix, configuration);
          if (sanitizedMatrix.length === 0) {
            toastError(`Aucune donnée n'a pu être chargée`);
            return Promise.resolve();
          }

          const creationRequestList = sanitizedMatrix.map((row) =>
            convertImportMatrixRowToCreationRequest(row, configuration)
          );

          const {
            filteredCreationRequestList,
            referenceTrancheDuplicateErrors,
          } = extractTrancheDuplicates(creationRequestList);
          const validation = checkDataValid(filteredCreationRequestList);

          if (referenceTrancheDuplicateErrors?.length) {
            toastError(
              `Erreur à l'import de ${referenceTrancheDuplicateErrors.length} références promoteur avec des tranches identiques.`
            );
            this.SetSuiviCommercialisationReferenceTrancheErrors(
              referenceTrancheDuplicateErrors
            );
          }
          if (!validation.isDataValid) {
            toastError(
              `L'import n'a pas pu avoir lieu car la colonne CA X (total, acté ou réservé) contient des caractères non numériques. \n Ligne ${validation.errorLineNumber}`
            );
          } else {
            commercialisationApi
              .uploadCommercialisationJSONData(
                filteredCreationRequestList,
                this.suiviCommercialisationFile
              )
              .then(async (response) => {
                if (response.status === 200) {
                  await this.fetchCommercialisationAllErrors();
                  this.SetCommercialisationTableau(response.data);
                  if (
                    response.data.suiviCommercialisationTableauLigneList.length
                  ) {
                    toastSuccess(
                      "Données du suivi de commercialisation ajoutées avec succès"
                    );
                  } else {
                    toastSuccess(
                      "Données du suivi de commercialisation ajoutées avec succès mais aucune donnée ne concorde avec les demandes existantes"
                    );
                  }
                } else {
                  toastError(
                    "Échec lors de l'ajout des données du suivi de commercialisation"
                  );
                }
              })
              .catch(() =>
                toastError(
                  "Échec lors de l'ajout des données du suivi de commercialisation"
                )
              );
          }
        } else {
          toastError(
            "Échec lors de l'ajout des données du suivi de commercialisation"
          );
        }
      },

      async createTrancheSplit(
        tranchesAmounts: Record<string, SuiviCommercialisationTrancheAmounts>
      ): Promise<void> {
        const trancheDemandeCreationRequest = this.fixTrancheAmount.map(
          (trancheAmount) => {
            const idDemande = trancheAmount.demande.id;
            return {
              idDemande,
              idLigne: trancheAmount.tranche.id,
              euroCentimeChiffreAffaireTtcTotal: convertEuroToCentime(
                tranchesAmounts[idDemande].caTotal
              ),
              euroCentimeChiffreAffaireTtcActe: convertEuroToCentime(
                tranchesAmounts[idDemande].caActe
              ),
              euroCentimeChiffreAffaireTtcReserve: convertEuroToCentime(
                tranchesAmounts[idDemande].caReserve
              ),
              nombreLotTotal: tranchesAmounts[idDemande].nbLotTotal,
              nombreLotActe: tranchesAmounts[idDemande].nbLotActe,
              nombreLotReserve: tranchesAmounts[idDemande].nbLotReserve,
            };
          }
        );
        await commercialisationApi
          .createTrancheDemande(trancheDemandeCreationRequest)
          .then((response) => {
            if (response.status === 201) {
              toastSuccess(
                "L'édition de tranche a été sauvegardée avec succès"
              );
            } else {
              toastError("Échec lors de la sauvegarde d'édition de tranche");
            }
          });
      },

      async saveSuiviCommercialisationImportConfiguration(): Promise<void> {
        if (
          isCommercialisationImportReady(
            this.commercialisationImportConfiguration
          )
        ) {
          const configuration = this.commercialisationImportConfiguration;

          commercialisationApi
            .saveImportConfiguration(configuration)
            .then((response) => {
              if (response.status === 200) {
                toastSuccess(
                  "votre configuration d'importation des données de commercialisation sauvegardée avec succès"
                );
              } else {
                toastError(
                  "Échec lors de la sauvegarde de votre configuration d'importation des données"
                );
              }
            })
            .catch(() =>
              toastError(
                "Échec lors de la sauvegarde de votre configuration d'importation des données"
              )
            );
        }
      },

      async fetchCommercialisationTableau(): Promise<void> {
        const response = await commercialisationApi.fetchAllForPromoteur();
        if (Object.keys(response.data).length) {
          this.SetCommercialisationTableau(response.data);
        }
      },

      async findCommercialisationImportConfiguration(): Promise<void> {
        const response = await commercialisationApi.findImportConfiguration();
        if (response.status === 200) {
          this.SetCommercialisationImportConfiguration(response.data);
          this.SetPreviewMode(true);
          this.SetCommercialisationIsConfigurationImported(true);
        }
      },

      async findCommercialisationImportDisplayConfiguration(): Promise<void> {
        const response = await commercialisationApi.findImportConfiguration();
        if (response.status === 200) {
          this.SetCommercialisationImportConfiguration(response.data);
        }
      },

      async fetchCommercialisationAllErrors(): Promise<void> {
        const response = await commercialisationApi.findAllErrors();

        if (Object.keys(response.data).length) {
          this.SetCommercialisationActAllErreur(response.data);
        }
      },

      async fetchSuiviCommercialisationPromoteurList(): Promise<void> {
        const response =
          await commercialisationApi.fetchSuiviCommercialisationPromoteurList();

        if (Object.keys(response.data).length) {
          this.SetSuiviCommercialisationPromoteurList(response.data);
        }
      },

      async createTrancheDemande(
        trancheDemandeCreationRequest: TrancheDemandeCreationRequest[]
      ): Promise<void> {
        await commercialisationApi.createTrancheDemande(
          trancheDemandeCreationRequest
        );
      },

      async fetchCommercialisationTableauForBanque(
        idPromoteur: string
      ): Promise<void> {
        const response =
          await commercialisationApi.fetchAllForBanque(idPromoteur);
        if (Object.keys(response.data).length) {
          this.SetCommercialisationTableau(response.data);
        }
      },

      async fetchCommercialisationTrancheErrorListForBanque(
        idPromoteur: string
      ): Promise<void> {
        const response =
          await commercialisationApi.findTrancheErrorListForBanque(idPromoteur);

        if (response.data) {
          this.SetSuiviCommercialisationUnboundTrancheErrors(response.data);
        }
      },

      async downloadCommercialisationExcelForBanque(
        idPromoteur: string
      ): Promise<void> {
        try {
          const response =
            await commercialisationApi.downloadCommercialisationExcelForBanque(
              idPromoteur
            );
          const buffer = Buffer.from(response.data as string, "base64");
          downloadClickLink(buffer, {
            name: "export_suivi_commercialisation.xlsx",
            type: NemmoMimeType.EXCEL,
          });
        } catch (error) {
          console.debug(error);
          toastError(
            "Erreur lors du téléchargement de l'export de suivi de commercialisation."
          );
        }
      },

      async updateSuiviCommercialisation(): Promise<void> {
        try {
          const response =
            await commercialisationApi.updateSuiviCommercialisation(
              this.suiviCommercialisationUpdateRequestList
            );

          if (response && response.status === 200) {
            toastSuccess(`L’état de commercialisation a été modifié.
        Il va être intégrée au suivi de commercialisation.`);
          } else {
            toastWarn(
              "La modification est bien effective mais au moins une modification ne correspond pas à des opérations connues."
            );
          }
        } catch (error) {
          console.debug(error);
          toastError("Une erreur a à nouveau été détectée.");
        } finally {
          // Perform the actions after the promise settles in the regular `finally` block
          await Promise.all([
            this.fetchCommercialisationTableau(),
            this.fetchCommercialisationAllErrors(),
            this.findCommercialisationImportDisplayConfiguration(),
          ]);
          this.ResetSuiviCommercialisationUpdateRequestList();
        }
      },
    },
  }
);
