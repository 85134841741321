import { Role } from "@domain/enum/role";
import { User } from "@domain/dto/user";
import { SignataireHeavy } from "@domain/dto/signataireHeavy";
import { intersection } from "lodash";
import { TiersUserType } from "@domain/enum/tiersUserType";
import { AnnuaireGroupTree } from "@domain/dto/annuaireGroupTree";
import { TiersUserCreationRequest } from "@domain/dto/tiersUserCreationRequest";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { TiersUserUpdateRequest } from "@domain/dto/tiersUserUpdateRequest";
import { OperationHeavy } from "@/domain/operationHeavy";
import { Group } from "@domain/dto/group";
import { extractPropertyFromList } from "@/utils/sanitizeUtils";
import { PoolOperationHeavy } from "@/domain/dto/poolOperationHeavy";
import { AnnuaireGroup } from "@domain/dto/annuaireGroup";

export const MAXIMUM_USER_NAME_LENGTH = 255;

export function getPartnerLabel(userRoleList: string[]): string {
  if (userRoleList.includes(Role.PROMOTEUR)) {
    return "banque";
  } else if (userRoleList.includes(Role.BANQUE)) {
    return "promoteur";
  }
  return "";
}

export function setUserOnTop<T extends { id: string }>(
  unsortedUserList: T[],
  idTopUser: string
): T[] {
  const userList = unsortedUserList.filter((user) => idTopUser !== user.id);

  const me = unsortedUserList.find((user) => idTopUser === user.id);

  if (me) {
    userList.unshift(me);
  }

  return userList;
}

export function findUserWithoutPhoneNumber(
  signataireList: SignataireHeavy[]
): string[] {
  return signataireList
    .filter(
      (signataire) =>
        !signataire.user.enabledOtpMail && signataire.user.phoneNumber === ""
    )
    .map((signataire) => {
      return `${signataire.user.firstName} ${signataire.user.lastName}`;
    });
}

function capitalizeString(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function capitaliseFirstAndLastName(
  firstName: string,
  lastName: string
): string[] {
  return [capitalizeString(firstName), capitalizeString(lastName)];
}

export function hasUserConductedFirstLogin(user: User | AnnuaireUser): boolean {
  return !!user.firstName && !!user.lastName;
}

export function isParentGroupUser(
  user: User,
  groupTree: AnnuaireGroupTree
): boolean {
  return (
    user.groups.some((group) => !group.parent) && groupTree.children.length > 0
  );
}

export function convertUserToAnnuaireUser(user: User): AnnuaireUser {
  return {
    id: user.id,
    firstName: user.id,
    lastName: user.id,
    email: user.id,
    phoneNumber: user.id,
    idProfileImage: user.id,
    jobTitle: user.id,
    absent: false,
    groups: convertGroupListToAnnuaireGroupList(user.groups),
    roles: user.roles,
  };
}

function convertGroupListToAnnuaireGroupList(
  groupList: Group[]
): AnnuaireGroup[] {
  return groupList.map((g) => convertGroupToAnnuaireGroup(g));
}

function convertGroupToAnnuaireGroup(group: Group): AnnuaireGroup {
  return {
    id: group.id,
    idGroupParent: group.parent?.id,
    name: group.name,
    roles: group.roles,
  };
}

export function areBothUsersInSameGroup(
  firstUser: AnnuaireUser,
  secondUser: AnnuaireUser
): boolean {
  const firstUserIdList = firstUser.groups
    .filter((group) => intersection(group.roles, [Role.PROMOTEUR, Role.BANQUE]))
    .map((group) => group.id);
  const secondUserIdList = secondUser.groups
    .filter((group) => intersection(group.roles, [Role.PROMOTEUR, Role.BANQUE]))
    .map((group) => group.id);

  return intersection(firstUserIdList, secondUserIdList).length > 0;
}

export function getUserGroupFromOperation(
  operation: OperationHeavy,
  user: User
): Group | undefined {
  return operation.groups.find((group) =>
    extractPropertyFromList(user.groups, "id").includes(group.id)
  );
}

export function getUserPoolGroupFromOperation(
  operation: PoolOperationHeavy,
  user: User
): Group | undefined {
  return operation.participantList
    .map((participant) => participant.participant)
    .find((groupParticipant) =>
      user.groups.map((group) => group.id).includes(groupParticipant.id)
    );
}

export function getGroupIdFromUser(user: User | AnnuaireUser): string {
  return user.groups[0].id;
}

export function tiersUserTypeLabel(tierUserType: TiersUserType): string {
  switch (tierUserType) {
    case TiersUserType.NOTAIRE:
      return "Notaire";
    case TiersUserType.AUTRE:
      return "Autre";
    default:
      return "";
  }
}
export function tiersUserRoleLabel(roleList: Role[]): string {
  if (roleList.includes(Role.NOTAIRE)) {
    return "Notaire";
  }
  if (roleList.includes(Role.AUTRE)) {
    return "Autre";
  }
  return "";
}
export function isTiersCreationRequestValid(
  tiersCreationRequest: TiersUserCreationRequest
): boolean {
  return !!tiersCreationRequest.role || tiersCreationRequest.isMailEnabled;
}
export function isTiersUpdateRequestValid(
  tiersCreationRequest: Partial<TiersUserUpdateRequest>
): boolean {
  return (
    !!tiersCreationRequest.tiersRole || !!tiersCreationRequest.isMailEnabled
  );
}

export function isUserPromoteur(user: User): boolean {
  return user.groups
    .map((group) => group.roles)
    .flat()
    .includes(Role.PROMOTEUR);
}

export function isUserBanque(user: User): boolean {
  return user.groups
    .map((group) => group.roles)
    .flat()
    .includes(Role.BANQUE);
}
