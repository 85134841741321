import SockitSingleton from "@/api/sockit/sockit";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { computed, ref, watchEffect } from "vue";
import config from "@/config";

export const useSockit = () => {
  const keycloakStore = useKeycloakStore();

  const sockitInstance = ref<SockitSingleton | null>(null);
  const token = computed(() => keycloakStore.getAuth?.token);

  watchEffect(() => {
    if (token.value) {
      sockitInstance.value = SockitSingleton.getInstance(token.value);
    } else {
      sockitInstance.value = null;

      if (config.NEOMI !== "test") {
        console.error("Token inaccessible");
      }
    }
  });

  return { sockitInstance, token };
};
