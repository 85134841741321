import { Dictionary } from "lodash";
import maxBy from "lodash/maxBy";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import { OperationDocument } from "@domain/dto/operationDocument";
import { OperationDocumentWithPreviousVersions } from "@domain/dto/operationDocumentWithPreviousVersions";
import { DocumentStatus } from "@domain/enum/documentStatus";
import { SortingOrder } from "@domain/enum/sortingOrder";
import { OperationDocumentCreationRequest } from "@domain/dto/operationDocumentCreationRequest";
import { FileUploadOperationMetadata } from "@domain/dto/fileQueueMetadata";
import { OperationDocumentCategory } from "@domain/enum/operationDocumentCategory";

export function formatCategoryDocument(documentCategory: string): string {
  if (
    documentCategory ===
    OperationDocumentCategory.SUIVI_COMMERCIALISATION_DETAIL_LOTS
  ) {
    return "Suivi";
  }
  if (documentCategory === OperationDocumentCategory.FINANCIER_SUIVI_BUDGET) {
    return "Financier";
  }
  return documentCategory;
}

// TODO faire TU
export function getOperationDocumentListLatestVersion(
  documentList: OperationDocument[],
): OperationDocumentWithPreviousVersions[] {
  const demandeProjectDocumentList = documentList.filter(
    (document) =>
      document.idDemande &&
      document.documentStatus &&
      [DocumentStatus.PROJET, DocumentStatus.ACTE].includes(
        document.documentStatus,
      ),
  );

  const lettreAccordProjectDocumentList = documentList.filter(
    (document) =>
      document.idLettreAccord &&
      document.documentStatus === DocumentStatus.PROJET,
  );

  const projectList = [
    ...demandeProjectDocumentList,
    ...lettreAccordProjectDocumentList,
  ];

  const otherDocumentList = documentList.filter(
    (document) =>
      !projectList.map((project) => project.id).includes(document.id),
  );

  const latestDemandeProjectListWithPreviousVersion =
    buildDemandeProjectListWithPreviousVersion(demandeProjectDocumentList);

  const latestLettreAccordProjectListWithPreviousVersion =
    buildLettreAccordProjectListWithPreviousVersion(
      lettreAccordProjectDocumentList,
    );

  const latestDocumentListWithPreviousVersion =
    buildOperationDocumentListWithPreviousVersion(otherDocumentList);

  return [
    ...latestDemandeProjectListWithPreviousVersion,
    ...latestLettreAccordProjectListWithPreviousVersion,
    ...latestDocumentListWithPreviousVersion,
  ];
}

function buildDemandeProjectListWithPreviousVersion(
  demandeProjectDocumentList: OperationDocument[],
): OperationDocumentWithPreviousVersions[] {
  const demandeProjectDocumentListByDemande = groupBy(
    demandeProjectDocumentList,
    (demandeProjectDocument) => demandeProjectDocument.idDemande,
  );

  return buildProjectListWithPreviousVersion(
    demandeProjectDocumentListByDemande,
  );
}

function buildLettreAccordProjectListWithPreviousVersion(
  lettreAccordProjectDocumentList: OperationDocument[],
): OperationDocumentWithPreviousVersions[] {
  const lettreAccordProjectDocumentListByDemande = groupBy(
    lettreAccordProjectDocumentList,
    (demandeProjectDocument) => demandeProjectDocument.idLettreAccord,
  );

  return buildProjectListWithPreviousVersion(
    lettreAccordProjectDocumentListByDemande,
  );
}

// TODO faire TU
function buildProjectListWithPreviousVersion(
  projectDocumentList: Dictionary<OperationDocument[]>,
): OperationDocumentWithPreviousVersions[] {
  const latestProjectList: OperationDocumentWithPreviousVersions[] = [];

  for (const projectList of Object.values(projectDocumentList)) {
    const latestProject = maxBy(
      projectList,
      (value: OperationDocument) => value.createdAt,
    );
    const previousProjectList = projectList.filter(
      (project) => project.id !== latestProject?.id,
    );

    latestProjectList.push({
      ...(latestProject as OperationDocument),
      previousVersionDocumentList: orderBy(
        previousProjectList,
        (doc) => doc.createdAt,
        SortingOrder.DESCENDING,
      ),
    });
  }

  return latestProjectList;
}

function buildOperationDocumentListWithPreviousVersion(
  operationDocumentList: OperationDocument[],
): OperationDocumentWithPreviousVersions[] {
  const latestDocumentList: OperationDocumentWithPreviousVersions[] = [];

  const groupedOperationDocuments = groupBy(
    operationDocumentList,
    (document) => {
      return `${document.name}.${document.category}.${document.idBanque}`;
    },
  );

  for (const documentList of Object.values(groupedOperationDocuments)) {
    const latestDocument = maxBy(
      documentList,
      (value: OperationDocument) => value.createdAt,
    );
    const previousDocumentList = documentList.filter(
      (document) => document.id !== latestDocument?.id,
    );

    latestDocumentList.push({
      ...(latestDocument as OperationDocument),
      previousVersionDocumentList: orderBy(
        previousDocumentList,
        (doc) => doc.createdAt,
        SortingOrder.DESCENDING,
      ),
    });
  }

  return latestDocumentList;
}

export function getForbiddenCharacters(word: string): string[] {
  const forbiddenCharacters = /[$€£¥/\\]/g;
  const matches = word.match(forbiddenCharacters);
  return matches ? Array.from(new Set(matches)) : []; // returns unique invalid characters
}

export function buildOperationDocumentCreationRequestList(
  fileUploadRedisMetadata: FileUploadOperationMetadata,
  idUser: string,
  idBanqueList?: string[],
): OperationDocumentCreationRequest[] {
  if (!idBanqueList?.length) {
    return [
      {
        idUploader: idUser,
        idConditionMiseEnPlace: fileUploadRedisMetadata.idConditionMiseEnPlace,
        fileName: fileUploadRedisMetadata.name,
        idBanque: fileUploadRedisMetadata.idBanque,
        category: fileUploadRedisMetadata.category,
        idDocument: fileUploadRedisMetadata.fileGenericMetadata.idDocument,
        idOperation: fileUploadRedisMetadata.idOperation,
        size: fileUploadRedisMetadata.fileGenericMetadata.size,
        byteSize: fileUploadRedisMetadata.fileGenericMetadata.size,
        fileMimetype: fileUploadRedisMetadata.fileGenericMetadata.mimeType,
        idUploadBatch:
          fileUploadRedisMetadata.fileGenericMetadata.idUploadBatch,
      },
    ];
  } else {
    return idBanqueList.map((idBanque) => {
      return {
        idUploader: idUser,
        idConditionMiseEnPlace: fileUploadRedisMetadata.idConditionMiseEnPlace,
        fileName: fileUploadRedisMetadata.name,
        category: fileUploadRedisMetadata.category,
        idDocument: fileUploadRedisMetadata.fileGenericMetadata.idDocument,
        idOperation: fileUploadRedisMetadata.idOperation,
        size: fileUploadRedisMetadata.fileGenericMetadata.size,
        byteSize: fileUploadRedisMetadata.fileGenericMetadata.size,
        fileMimetype: fileUploadRedisMetadata.fileGenericMetadata.mimeType,
        idUploadBatch:
          fileUploadRedisMetadata.fileGenericMetadata.idUploadBatch,
        idBanque,
      };
    });
  }
}
