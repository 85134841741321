import { OperationLight } from "@/domain/dto/operationLight";
import uniq from "lodash/uniq";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { getLatestByUpdatedAt } from "@/utils/dateUtils";
import { Role } from "@domain/enum/role";
import { hasUserConductedFirstLogin } from "@/utils/userUtils";
import { Demande } from "@/domain/demande";
import { hasRole } from "@/utils/groupUtils";

export function findReferentUserIdListFromOperationLightList(
  operationList: OperationLight[]
): string[] {
  const userIdList: string[] = [];

  operationList.forEach((operation) => {
    if (operation.referentPromoteur1) {
      userIdList.push(operation.referentPromoteur1);
    }
    if (operation.referentPromoteur2) {
      userIdList.push(operation.referentPromoteur2);
    }
    if (operation.referentPromoteur3) {
      userIdList.push(operation.referentPromoteur3);
    }
    if (operation.referentBanque1) {
      userIdList.push(operation.referentBanque1);
    }
    if (operation.referentBanque2) {
      userIdList.push(operation.referentBanque2);
    }
    if (operation.referentBanque3) {
      userIdList.push(operation.referentBanque3);
    }
  });

  return uniq(userIdList);
}

export function isUserParentOrInLatestDemande(
  user: AnnuaireUser,
  idBanqueFromLatestUpdatedDemande: string | undefined
): boolean {
  return (
    user.groups.filter(
      (g) =>
        idBanqueFromLatestUpdatedDemande === g.id ||
        g.idGroupParent === undefined
    ).length > 0
  );
}

export function filterPotentialReferentList(
  operationMemberList: AnnuaireUser[],
  idUserActualReferentList: string[],
  currentUserRole: Role,
  demandeList: Demande[]
): AnnuaireUser[] {
  const operationMembers = operationMemberList
    .filter(
      (user) =>
        !idUserActualReferentList.includes(user.id) &&
        hasUserConductedFirstLogin(user)
    )
    .filter((user) => hasRole(user.roles, currentUserRole));

  if (currentUserRole === Role.BANQUE) {
    const latestActiveIdBanque = getLatestByUpdatedAt(demandeList)?.idBanque;
    return operationMembers.filter((user) =>
      isUserParentOrInLatestDemande(user, latestActiveIdBanque)
    );
  }
  return operationMembers;
}
