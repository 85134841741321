<!--suppress CssUnusedSymbol -->
<template>
  <svg
    :height="size"
    :width="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99953 6.71063C8.60953 7.10063 8.60953 7.73063 8.99953 8.12063L12.8795 12.0006L8.99953 15.8806C8.60953 16.2706 8.60953 16.9006 8.99953 17.2906C9.38953 17.6806 10.0195 17.6806 10.4095 17.2906L14.9995 12.7006C15.3895 12.3106 15.3895 11.6806 14.9995 11.2906L10.4095 6.70063C10.0295 6.32063 9.38953 6.32063 8.99953 6.71063Z"
      :class="iconColor"
    />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "24",
    },
  },
  setup(props) {
    const iconColor = computed((): string => {
      if (props.color === "tile-details") return "tile-details-color";
      if (props.color === "operation-banner") return "operation-banner-color";
      return "default";
    });

    return {
      // Computed
      iconColor,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.default {
  fill: $default-font;
}

.tile-details-color {
  fill: $font-2;
}

.operation-banner-color {
  fill: $operation-banner-text;
}
</style>
