<template>
  <svg
    :height="size"
    :width="size"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="classColor"
      clip-rule="evenodd"
      d="M12.8401 2.61743C14.4501 4.58743 15.3201 6.95743 15.4801 9.37743C14.2001 10.0674 13.0201 10.9474 11.9901 12.0074C10.9601 10.9374 9.78005 10.0574 8.50005 9.37743C8.70005 6.96743 9.62005 4.59743 11.2701 2.61743C11.6801 2.11743 12.4301 2.11743 12.8401 2.61743ZM8.89942 11.9721C8.92687 11.9903 8.95381 12.0087 8.98005 12.0274C8.95769 12.0115 8.93482 11.996 8.91156 11.9808C8.90752 11.9779 8.90347 11.975 8.89942 11.9721ZM8.89942 11.9721C7.24673 10.7868 5.2864 10.0149 3.16005 9.80743C2.52005 9.74743 1.99005 10.2774 2.05005 10.9174C2.50005 15.7174 5.70005 19.6974 10.0301 21.2474C10.6601 21.4774 11.3201 21.6474 12.0001 21.7574C12.6801 21.6374 13.3301 21.4674 13.9701 21.2474C18.3001 19.6974 21.5001 15.7274 21.9501 10.9174C22.0101 10.2774 21.4701 9.74743 20.8401 9.80743C17.1301 10.1674 13.9401 12.2274 11.9901 15.1974C11.1665 13.9431 10.1217 12.851 8.91156 11.9808C8.86622 11.9513 8.8194 11.9229 8.77201 11.8942L8.772 11.8942L8.772 11.8942C8.69151 11.8454 8.60938 11.7956 8.53005 11.7374C8.59299 11.7794 8.65769 11.8196 8.72194 11.8595C8.78211 11.8969 8.84187 11.934 8.89942 11.9721Z"
      fill="#2FA743"
      fill-rule="evenodd"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LeafIcon",
  props: {
    classColor: { type: String, default: "green" },
    size: { type: String, default: "24" },
  },
});
</script>
<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;

.green {
  fill: $green2;
}
.grey {
  fill: $font-3;
}
</style>
